import React, { useEffect, useState } from "react";
import generateAndSaveInvoice from "../services/invoiceService";
// import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Cookies from "js-cookie";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import toast from "react-hot-toast";
import { Add } from "@mui/icons-material";
import { motion } from "framer-motion";
import { AiOutlineNumber } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { RiHistoryLine } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../Pages/OverlayDisableAccountPage";
import { FaCheck } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import { load } from "@cashfreepayments/cashfree-js";
import "./wallet.css";
import PaginationComponent from "../utils/PaginationCompoent";
import WalletShimmer from "../utils/WalletShimmer";
import ErrorPage from "../utils/ErrorPage";
import { formateDate } from "../utils/helperFunction";
import ApiInterceptor from "../utils/ApiInterceptor";
import { config } from "../config";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Wallet() {
  let cashfree;

  let insitialzeSDK = async function () {
    cashfree = await load({
      mode: "sandbox",
    });
  };

  insitialzeSDK();

  const [orderId, setOrderId] = useState("");

  const { currentUser } = useSelector((state) => state.user);
  const [visible, setVisible] = useState(false);
  const [money, setMoney] = useState("");

  const [paymentMethod, setPaymentMethod] = useState("");

  const handlePaymentSelection = (method) => {
    setPaymentMethod(method);
  };

  // const current = currentUser?.others ? currentUser?.others : currentUser;
  // console.log("user", current);
  // const current = "65bcce82a84141a91c4108e0";
  const [transactions, setTransactions] = useState([]);
  const [escrowTranscations, setEscrowTranscations] = useState([]);
  const [businessEscrowWallet, setBusinessEscrowWallet] = useState([]);
  const [businessEscrowTranscation, setBusinessEscrowTranscation] = useState(
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const rowsPerPage = 20;

  const [transactionData, settransactionData] = useState([]);
  const [escrowTransactionData, setescrowTransactionData] = useState([]);

  const companyId = Cookies.get("userid");

  const dispatch = useDispatch();
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);
  const [openDisablePageModal, setOpenDisablePageModal] = useState(false);
  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);

  const [wallet, setWallet] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const walletResponse = await ApiInterceptor.get(
          `businesswallet/getBusinessWalletByBusinessId/${companyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        setWallet(walletResponse?.data);

        // Get Transactions
        const transactionsResponse = await ApiInterceptor.get(
          `businesswallet/getBusinessTransaction/${companyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        setTransactions(transactionsResponse.data);

        settransactionData(
          transactionsResponse.data.slice(
            (currentPage - 1) * rowsPerPage,
            currentPage * rowsPerPage
          )
        );

        const transactionsecsrowResponse = await ApiInterceptor.get(
          `businesswallet/getBusinessEscrowTransaction/${companyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        setBusinessEscrowTranscation(transactionsecsrowResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [companyId]);

  useEffect(() => {
    const getbusinessEscrowWallet = async () => {
      try {
        const response = await ApiInterceptor.get(
          `businesswallet/getBusinessEscrowWallet/${companyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        // console.log(response.data);
        setBusinessEscrowWallet(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getbusinessEscrowWallet();
  }, [companyId]);

  const [data, setData] = useState(null);
  const [tabledata, setTableData] = useState([]);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJmOTY2ZWM4OTE1ODgwM2I2ZjQ5ZTQiLCJpYXQiOjE3MDcwNTQ3MDIsImV4cCI6MTczODYxMjMwMn0.mhfOyzYEUcjH0f4gcEMstiVF7_eqx_alGfrDQpTbtfI";
  const token1 =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJmOTY2ZWM4OTE1ODgwM2I2ZjQ5ZTQiLCJpYXQiOjE3MDcwNTQ3MDIsImV4cCI6MTczODYxMjMwMn0.mhfOyzYEUcjH0f4gcEMstiVF7_eqx_alGfrDQpTbtfI";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiInterceptor.get(
          `businesswallet/getBusinessEscrowWallet/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);
  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await ApiInterceptor.get(
          `businesswallet/getBusinessEscrowTransaction/${companyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        setTableData(response.data);

        setescrowTransactionData(
          response.data.slice(
            (currentPage2 - 1) * rowsPerPage,
            currentPage2 * rowsPerPage
          )
        );
      } catch (error) {
        toast.error("Error fetching data");
      }
    };

    fetchData1();
  }, [token1]);

  const flatBusinessTransactions = tabledata.flatMap(
    (item) => item.business_transaction
  );

  const handleGenerateInvoice = (options) => {
    // Create your invoice object

    const invoice = {
      orderNumber: options.order_id,
      date: new Date(),
      totalAmount: options.amount,
      // Include other invoice details based on your requirements
    };
    generateAndSaveInvoice(invoice)
      .then((generatedInvoice) => {})
      .catch((error) => {
        // Handle any errors that occur during generation and saving
        toast.error("Error generating and saving invoice");
      });
  };
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);

  const checkoutHandler = async (amount, paymentMethod) => {
    // const data1 = await axios.get("https://backend-vsal.onrender.com/api/getkey");
    if (amount <= 0 || amount === "" || amount.trim() === "") {
      // toast.error("Please enter a valid amount", {
      //   duration: 1000,
      // });
      setErrors({ amount: "Please enter a valid amount" });

      return;
    }
    if (paymentMethod === "Razorpay") {
      const data1 = await ApiInterceptor.get("getkey");

      const { data } = await ApiInterceptor.post("payment/checkout", {
        amount,
      });

      const options = {
        key: data1?.data?.key,
        amount: data.amount,
        currency: "INR",
        name: "Vsal",
        description: "Wallet payment",
        image: "",
        order_id: data.id,
        // callback_url: `https://api.cloutin.co/api/payment/verification/${data.amount}/${wallet._id}/${companyId}`,
        callback_url: `${config.REACT_APP_BACKEND_SERVICE_URL}payment/verification/${data.amount}/${wallet._id}/${companyId}`,
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#528FF0",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
      handleGenerateInvoice(options);
    } else if (paymentMethod === "Stripe") {
      const stripe = await loadStripe(
        "pk_test_51OHvRgSIiPYAQKAuoM6MwY07VlxKi6JCOXeuLiEoBE5rWRedP9PdovznzVnapKK03djF4wZ6WKTtKrLnGZipOmOw00us50DQcA"
      );

      const body = {
        amount: amount,
        walletId: wallet._id,
        companyId: companyId,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      // const response = await fetch(
      //   "https://api.cloutin.co/api/create-checkout-session",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: JSON.stringify(body),
      //   }
      // );
      const response = await ApiInterceptor.post(
        "create-checkout-session",
        body,
        {
          headers: headers,
        }
      );

      const session = await response.json();

      const result = stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result?.error) {
        setError(true);
      }
    } else {
      const getSessionId = async () => {
        try {
          const res = await ApiInterceptor.get("cashfree/payment", {
            params: {
              amount: amount,
              companyId: companyId,
              walletId: wallet._id,
            },
          });
          if (res.data && res.data.payment_session_id) {
            setOrderId(res.data.order_id);
            return res.data.payment_session_id;
          }
        } catch (err) {
          setError(true);
        } finally {
          setError(false);
        }
      };

      try {
        let sessionId = await getSessionId();
        let checkoutOptions = {
          paymentSessionId: sessionId,
          redirectTarget: "_self",
        };

        cashfree.checkout(checkoutOptions).then((res) => {
          console.log("payment initialized");
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showBalance, setShowBalance] = useState(false);
  const handleOnChange = () => {
    setShowBalance(!showBalance);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    settransactionData(
      transactions.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };
  const handlePageChange2 = (page) => {
    setCurrentPage2(page);
    setescrowTransactionData(
      tabledata.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    );
  };

  const [sortType, setSortType] = useState("asc");
  const [sortType2, setSortType2] = useState("asc");

  const handleSort = () => {
    if (sortType === "asc") {
      transactions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      handlePageChange(currentPage);
      setSortType("desc");
    } else {
      transactions.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      handlePageChange(currentPage);
      setSortType("asc");
    }
  };
  const handleSort2 = () => {
    if (sortType2 === "asc") {
      tabledata.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      handlePageChange2(currentPage2);
      setSortType2("desc");
    } else {
      tabledata.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      handlePageChange2(currentPage2);
      setSortType2("asc");
    }
  };

  if (error) {
    return <ErrorPage />;
  }
  if (userAccountStatus) {
    return <OverlayDisableAccountPage isOpen={true} />;
  }

  return (
    <div className=" my-[50px] flex items-center justify-center  h-[calc(100vh-60px)] xl:w-[70%] w-full lg:h-[calc(100vh-105px)] sm:my-[50px] md:my-[50px] mobile:my-[30px] mobile:flex mobile:items-center mobile:justify-center">
      {!visible ? (
        <div className="space-y-[20px] h-[100%] md:basis-[50%] mr-[20px] md:space-y-[20px]">
          {/* tabs starts */}
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: 1,
                borderColor: "divider",
                color: "black",
                // border: "2px solid red",

                width: "100%",
                marginTop: "-20px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "black", // Change this to your desired color
                  },
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    flexGrow: 1,

                    "&:hover": {
                      color: "black",
                      backgroundColor: "white",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "white",
                    },
                  },
                }}
              >
                <Tab label="Wallet" {...a11yProps(0)} />
                <Tab label="Escrow Wallet" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {wallet ? (
              <CustomTabPanel value={value} index={0}>
                <div className="flex flex-row items-center justify-center gap-5 w-[100%] 8k:ml-[10vw] 3xl:w-[50vw] 4k:w-[50vw] rk:w-[50vw] rk:ml-[7vw] 1440k:w-[50vw] 1440k:ml-[7vw] lgk:w-[50vw] lgk:ml-[7vw]">
                  <div className=" flex flex-col gap-5 ml-5 w-[70%] 8k:w-[10vw] sm:w-[40vw] md:w-[40vw] lgk:gap-4 ">
                    <div className="bg-white rounded-lg px-[20px] py-[20px] 8k:h-[9vw]  3xl:h-[8vw] 4k:h-[8vw] 1440k:h-[12vw] lgk:h-[13vw] shadow-md hover:shadow-xl">
                      <div className="flex justify-between items-center">
                        <h1 className="text-[#b2aeae]">Total Balance</h1>
                        {/* <img className="w-1 " src="/Images/ham2.png" alt="ham2" /> */}
                      </div>

                      <div className="flex justify-between items-center">
                        <motion.h1
                          className="text-black text-lg md:text-xl lg:text-2xl font-bold"
                          animate={{ opacity: 1, scale: 1 }}
                          initial={{ opacity: 0, scale: 0.8 }}
                          transition={{ duration: 0.3 }}
                        >
                          {` Rs ${
                            wallet ? wallet?.currentBalance?.toFixed(4) : "0"
                          }`}
                        </motion.h1>
                      </div>

                      <div className="flex my-[10px]">
                        <div className="rounded-lg cursor-pointer  bg-blue-400 justify-between text-white flex items-center px-3 py-2 4k:h-[2vw] lgk:h-[4vw] ">
                          <Add />
                          <h1
                            className="1440k:text-[1vw] lgk:text-[1vw]"
                            onClick={() => setVisible(true)}
                          >
                            Add Money
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw] shadow-md hover:drop-shadow-md border border-blue-100">
                      <div className="flex items-center">
                        <img
                          className="w-[25%] h-[70%] mr-[20px] 4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw] drop-shadow-md"
                          src="/Images/rs.png"
                          alt="rs"
                        />
                        <div className="w-[70%] flex flex-col">
                          <h1 className="text-[#b2aeae] text-[14px]">
                            Total Spend
                          </h1>
                          <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]">
                            Rs {wallet ? wallet?.totalSpent?.toFixed(4) : "0"}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw] shadow-md hover:drop-shadow-md border border-blue-100">
                      <div className="flex items-center">
                        <img
                          className="w-[25%] h-[70%] mr-[20px]  4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw] drop-shadow-md"
                          src="/Images/rs.png"
                          alt="rs"
                        />
                        <div className="w-[70%] flex flex-col">
                          <h1 className="text-[#b2aeae] text-[14px]">
                            Total Recharged
                          </h1>
                          <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]">
                            Rs {wallet ? wallet.totalRecharged : "0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-lg bg-white relative group py-[10px]  p-3 overflow-x-scroll md:w-full mt-10">
                  {/* <div className="rounded-lg bg-white py-[10px] w-[100%] p-3 overflow-x-scroll md:w-full 8k:w-[60vw] sm:w-full md:w-[70vw] "> */}
                  <div className="flex flex-row justify-between ">
                    <div>
                      <h1 className="text-[#455468] font-bold text-lg p-8 ml-5">
                        Transactions
                      </h1>
                    </div>
                    <div className="p-5 space-x-2">
                      <input
                        placeholder="Search transactions...."
                        className="outline-none focus:outline-none border-[2px] border-gray-400 px-3 py-2 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="w-full overflow-x-auto">
                    <table className="w-full table-auto border-collapse">
                      <thead>
                        <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
                          <th className="py-3 px-6 text-left">
                            <AiOutlineNumber className="inline-block mr-2" />
                            Order No.
                          </th>
                          <th
                            className="py-3 px-6 text-left cursor-pointer"
                            onClick={() => handleSort()}
                          >
                            <CiCalendarDate className="inline-block mr-2" />
                            Date
                            {sortType === "asc" ? (
                              <ArrowDownwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            ) : (
                              <ArrowUpwardIcon
                                fontSize="small"
                                className=" mb-1"
                              />
                            )}
                          </th>
                          <th className="py-3 px-6 text-left">
                            <RiHistoryLine className="inline-block mr-2" />
                            State
                          </th>
                          <th className="py-3 px-6 text-left">
                            <GrTransaction className="inline-block mr-2" />
                            Trx. Type
                          </th>
                          <th className="py-3 px-6 text-left">Total Price</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm font-light">
                        {transactionData && transactionData.length > 0 ? (
                          transactionData.map((transaction) => (
                            <tr
                              key={transaction?._id}
                              className="border-b border-gray-200 hover:bg-gray-100 text-center pb-2"
                            >
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                Wl-{transaction._id.slice(-4)}
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap ">
                                {formateDate(transaction?.createdAt)}
                              </td>
                              <td className="py-3 px-6  whitespace-nowrap uppercase font-semibold cursor-pointer">
                                <span
                                  className={` py-2 rounded-md font-bold ${
                                    transaction.creditorDebit === "credit"
                                      ? " text-green-500"
                                      : " text-red-500"
                                  }`}
                                >
                                  {transaction.creditorDebit}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-center whitespace-nowrap capitalize">
                                {transaction.txnType}
                              </td>
                              <td className="py-3 px-6 text-center whitespace-nowrap">
                                &#x20B9;{transaction?.amount?.toFixed(4)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="5"
                              className="py-3 px-6 text-center font-semibold"
                            >
                              No Transactions Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <PaginationComponent
                      totalItems={transactions.length}
                      itemsPerPage={rowsPerPage}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </CustomTabPanel>
            ) : (
              <WalletShimmer />
            )}
            {/* escrow wallet transcation */}
            <CustomTabPanel value={value} index={1}>
              <div className="flex flex-row items-center justify-center gap-5 w-[100%] 8k:ml-[10vw] 3xl:w-[50vw] 4k:w-[50vw] rk:w-[50vw] rk:ml-[7vw] 1440k:w-[50vw] 1440k:ml-[7vw] lgk:w-[50vw] lgk:ml-[7vw]">
                <div className=" flex flex-col gap-5 ml-5 w-[70%] 8k:w-[10vw] sm:w-[40vw] md:w-[40vw] lgk:gap-4">
                  <div className="bg-white rounded-lg px-[20px] py-[20px] 8k:h-[9vw]  3xl:h-[8vw] 4k:h-[8vw] 1440k:h-[12vw] lgk:h-[13vw] shadow-md hover:shadow-xl">
                    <div className="flex justify-between items-center">
                      <h1 className="text-[#b2aeae]">Total Balance</h1>
                      {/* <img className="w-1 " src="/Images/ham2.png" alt="ham2" /> */}
                    </div>
                    <div className="flex justify-between items-center">
                      <h1 className="text-black text-[24px] font-bold 1440k:text-[1.4vw]">
                        {data
                          ? "Rs " +
                            data?.business_escrow_accts?.total_amount?.toFixed(
                              4
                            )
                          : "Rs 0"}
                      </h1>
                    </div>

                    <div className="flex my-[10px]"></div>
                  </div>

                  <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw] shadow-sm hover:shadow-md border border-blue-100">
                    <div className="flex items-center ">
                      <img
                        className="w-[25%] h-[70%] mr-[20px] 4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw] shadow-md"
                        src="/Images/rs.png"
                        alt="rs"
                      />
                      <div className="w-[70%] flex flex-col">
                        <h1 className="text-[#b2aeae] text-[14px]">
                          Total Disbursed
                        </h1>
                        <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]">
                          Rs{" "}
                          {data
                            ? data?.business_escrow_accts?.total_disbursed?.toFixed(
                                4
                              )
                            : "0"}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw] shadow-sm hover:shadow-md border border-blue-100">
                    <div className="flex items-center">
                      <img
                        className="w-[25%] h-[70%] mr-[20px]  4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw] shadow-md"
                        src="/Images/rs.png "
                        alt="rs"
                      />
                      <div className="w-[70%] flex flex-col">
                        <h1 className="text-[#b2aeae] text-[14px]">
                          Total Disputes
                        </h1>
                        <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]">
                          Rs{" "}
                          {data
                            ? data?.business_escrow_accts?.total_disputes?.toFixed(
                                4
                              )
                            : "0"}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw] shadow-sm hover:shadow-md border border-blue-100">
                    <div className="flex items-center">
                      <img
                        className="w-[25%] h-[70%] mr-[20px]  4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw] shadow-md"
                        src="/Images/rs.png"
                        alt="rs"
                      />
                      <div className="w-[70%] flex flex-col">
                        <h1 className="text-[#b2aeae] text-[14px]">
                          Total Orders
                        </h1>
                        <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]">
                          {data
                            ? data?.business_escrow_accts?.total_orders
                            : "0"}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 rounded-lg bg-white relative group py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full shadow-md">
                {/* <div className="rounded-lg bg-white py-[10px] w-[100%] p-3 overflow-x-scroll md:w-full 8k:w-[60vw] sm:w-full md:w-[70vw] "> */}
                <div className="flex flex-row justify-between">
                  <div>
                    <h1 className="text-[#455468] font-bold text-lg p-8 ml-5">
                      Escrow Wallet
                    </h1>
                  </div>
                  <div className="p-5 space-x-2">
                    <input
                      placeholder="Search transactions...."
                      className="rounded-md py-2 px-3 outline-none focus:outline-none border-[2px] border-gray-500"
                    />
                  </div>
                </div>
                <table className="table-auto  md:gap-2 text-center lg:w-[95%] rk:w-[60vw]  1440k:w-[60vw] lgk:w-[60vw] md:overflow-x-auto">
                  <thead className="text-xs">
                    <tr className=" font-normal bg-blue-500 text-white">
                      <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-2">
                        Order no.
                      </th>
                      <th
                        className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-2 cursor-pointer"
                        onClick={() => handleSort2()}
                      >
                        <img
                          className="inline-block pr-1"
                          src="/Images/Iconcalendar_31.png"
                          alt="calendar"
                        />
                        Date
                        {sortType2 === "asc" ? (
                          <ArrowDownwardIcon
                            fontSize="small"
                            className=" mb-1"
                          />
                        ) : (
                          <ArrowUpwardIcon fontSize="small" className=" mb-1" />
                        )}
                      </th>
                      <th className="text-xs  pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-2">
                        <img
                          className="inline-block pr-1"
                          src="/Images/Spinnerspinner.png"
                          alt="spinner"
                        />
                        State
                      </th>
                      <th className="text-xs  pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-2">
                        <img
                          className="inline-block pr-1"
                          src="/Images/Spinnerspinner.png"
                          alt="spinner"
                        />
                        Order Type
                      </th>
                      <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-2">
                        <img
                          className="inline-block pr-1"
                          src="/Images/CurrencyDollardollar.png"
                          alt="dollar"
                        />
                        Total Tax
                      </th>
                      <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw] py-2">
                        <img
                          className="inline-block pr-1"
                          src="/Images/Vectorlocation_vector.png"
                          alt="location"
                        />
                        Total Price
                      </th>
                    </tr>
                  </thead>

                  <tbody className="my-[20px] mx-[20px] space-y-[20px] md:w-[70vw] ">
                    {escrowTransactionData &&
                    escrowTransactionData.length > 0 ? (
                      <>
                        {escrowTransactionData.map((i) => (
                          <tr className="hover:bg-gray-50 ease-in-out border-b">
                            <td className="text-xs pt-[20px] md:text-lg pb-2">
                              <div className="flex justify-center items-center">
                                <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">
                                  EWL-{i._id && i._id.slice(-4)}
                                </h1>
                              </div>
                            </td>
                            <td className="text-xs pt-[20px] pb-2  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
                              {/* {new Date(i.createdAt).toLocaleDateString(
                                "en-GB"
                              )} */}
                              {formateDate(i?.createdAt)}
                            </td>
                            <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                              <span
                                className={`px-4 py-2 rounded-md font-bold  ${
                                  i?.business_transaction?.txn_type
                                    ? i?.business_transaction?.txn_type.toLowerCase() ===
                                      "debit"
                                      ? " text-red-500"
                                      : " text-green-500"
                                    : " text-green-500"
                                }`}
                              >
                                {i?.business_transaction?.txn_type
                                  ? i?.business_transaction?.txn_type.toUpperCase()
                                  : "CREDIT"}
                              </span>
                            </td>
                            <td className="text-xs pt-[20px] pb-2 md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
                              {i?.business_transaction &&
                                i?.business_transaction?.order_type}
                            </td>
                            <td className="text-xs pt-[20px] pb-2 md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
                              {i?.business_transaction &&
                                i?.business_transaction?.total_taxes?.toFixed(
                                  3
                                )}
                            </td>
                            <td className="text-xs pt-[20px] pb-2 md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
                              {i?.business_transaction &&
                                i?.business_transaction?.amount?.toFixed(3)}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="py-3 px-6 text-center text-gray-600 font-semibold"
                        >
                          No Transactions Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <PaginationComponent
                  totalItems={tabledata.length}
                  itemsPerPage={rowsPerPage}
                  currentPage={currentPage2}
                  onPageChange={handlePageChange2}
                />
              </div>
            </CustomTabPanel>
          </Box>
          {/* table  */}
        </div>
      ) : (
        <div className=" space-y-[20px] h-[60%] md:basis-[50%] px-2 mr-[20px] -mt-56 ">
          <form className="w-[40vw] bg-white shadow-md px-[20px] space-y-[20px] justify-between flex-col flex py-[20px] rounded-lg ">
            <div>
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="moneyInput"
              >
                Enter Amount to Add
              </label>

              <input
                className="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="moneyInput"
                type="text"
                placeholder="Enter amount"
                value={money}
                onChange={(e) => setMoney(e.target.value)}
                onKeyPress={(e) => {
                  const allowedChars = /^[0-9.]+$/;
                  const currentValue = e.target.value;
                  if (e.key === "." && currentValue.includes(".")) {
                    e.preventDefault();
                    toast.error("Only one decimal point is allowed", {
                      duration: 1000,
                    });
                  } else if (
                    !allowedChars.test(e.key) &&
                    e.key !== "Backspace"
                  ) {
                    e.preventDefault();
                  }
                }}
                title="Please enter a valid number"
                required
              />
              {
                <p className="text-red-500 text-xs italic mt-2 font-semibold">
                  {errors.amount && errors.amount}
                </p>
              }
            </div>

            <div>
              <label className="block text-gray-700 font-bold mb-2">
                Choose Payment Initiator
              </label>
              <button
                type="button"
                className={`w-full bg-transparent border border-gray-300 rounded-lg p-4 text-left focus:outline-none focus:border-green-500 relative transition-all ${
                  paymentMethod === "Stripe" ? "border-green-500" : ""
                }`}
                onClick={() => handlePaymentSelection("Stripe")}
              >
                <img
                  width={40}
                  height={40}
                  className=" inline pr-2 "
                  src="https://cdn.iconscout.com/icon/free/png-256/free-stripe-2-498440.png"
                  alt=""
                />
                Stripe
                {paymentMethod === "Stripe" && (
                  <FaCheck className="absolute top-1/2 right-4 transform -translate-y-1/2 text-green-500 animate-checkmark-enter" />
                )}
                {paymentMethod === "Stripe" && (
                  <FaCheck className="absolute top-1/2 right-4 transform -translate-y-1/2 text-green-500 animate-checkmark-steady" />
                )}
              </button>
              <button
                type="button"
                className={` mt-5 w-full bg-transparent border border-gray-300 rounded-lg p-4 text-left focus:outline-none focus:border-green-500 relative transition-all ${
                  paymentMethod === "Razorpay" ? "border-green-500" : ""
                }`}
                onClick={() => handlePaymentSelection("Razorpay")}
              >
                <img
                  className=" inline"
                  width={50}
                  height={50}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSL76RO4Ht8yAYra4fixURXfs2B3uAfnQypsWwyCpjbA&s"
                  alt=""
                />
                Razorpay
                {paymentMethod === "Razorpay" && (
                  <FaCheck className="absolute top-1/2 right-4 transform -translate-y-1/2 text-green-500 animate-checkmark-enter" />
                )}
                {paymentMethod === "Razorpay" && (
                  <FaCheck className="absolute top-1/2 right-4 transform -translate-y-1/2 text-green-500 animate-checkmark-steady" />
                )}
              </button>

              <button
                type="button"
                className={` mt-5 w-full bg-transparent border border-gray-300 rounded-lg p-4 text-left focus:outline-none focus:border-green-500 relative transition-all ${
                  paymentMethod === "CashFree" ? "border-green-500" : ""
                }`}
                onClick={() => handlePaymentSelection("CashFree")}
              >
                <img
                  className=" inline"
                  width={40}
                  height={40}
                  src="https://storage.googleapis.com/datanyze-data//technologies/a146b015e8eb3e923b4d285d5c1dc972f7f513de.png"
                  alt=""
                />
                CashFree
                {paymentMethod === "CashFree" && (
                  <FaCheck className="absolute top-1/2 right-4 transform -translate-y-1/2 text-green-500 animate-checkmark-enter" />
                )}
                {paymentMethod === "CashFree" && (
                  <FaCheck className="absolute top-1/2 right-4 transform -translate-y-1/2 text-green-500 animate-checkmark-steady" />
                )}
              </button>
            </div>
            <div className="flex justify-center">
              <button
                className={`w-full bg-blue-500 ${
                  paymentMethod && money ? "hover:bg-blue-700" : ""
                } text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline ${
                  !paymentMethod ? "opacity-50 cursor-not-allowed" : ""
                }`}
                type="button"
                onClick={() => checkoutHandler(money, paymentMethod)}
                // disabled={!paymentMethod || !money}
                disabled={!paymentMethod}
              >
                Add Funds
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default Wallet;
