import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiInterceptor from "../../utils/ApiInterceptor";
const UserAccountStatusSlice = createSlice({
  name: "userAccountStatus",
  initialState: {
    userAccountStatus: null,
  },
  reducers: {
    setUserAccountStatus: (state, action) => {
      state.userAccountStatus = action.payload;
    },
  },
});

export const { setUserAccountStatus } = UserAccountStatusSlice.actions;

export default UserAccountStatusSlice;

export const getUserAccountStatus = createAsyncThunk(
  "userAccountStatus/getUserAccountStatus",
  async ({ companyId }, { rejectWithValue }) => {
    try {
      // const response = await fetch(
      //   `https://api.cloutin.co/api/admin/business/getAccountStatus/:${companyId}`
      // );
      const resp = await ApiInterceptor.get(
        `/admin/business/getAccountStatus/:${companyId}`
      );
      console.log(resp?.data?.isAccountSuspended, "resp");
      // const data = await response.json();
      // console.log(data, "data");
      if (resp?.data) {
        return resp?.data?.isAccountSuspended;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Something went wrong");
    }
  }
);
