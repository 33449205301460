import React, { useState, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { SpinnerCircular } from "spinners-react";
import { loginError } from "../redux/Slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import "./createcommunity.css";
import Modal from "./Modal";
import { FaYoutube } from "react-icons/fa";
import { IoLogoInstagram, IoMdReturnLeft } from "react-icons/io";
import { hideLoading, showLoading } from "../redux/Slice/loadingSlice";
import DatePicker from "react-datepicker";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../Pages/OverlayDisableAccountPage";
import { categories } from "../constants/data";
import NetworkStatusOverlay from "../Pages/NetworkStatusOverlay";
import ErrorPage from "../utils/ErrorPage";
import ApiInterceptor from "../utils/ApiInterceptor";
import { IoMdShare } from "react-icons/io";
import { GoArrowLeft } from "react-icons/go";
import { IoCopyOutline } from "react-icons/io5";
import { MdFileDownload } from "react-icons/md";

function CreateCampaign() {
  const { loading } = useSelector((state) => state.loading);
  const [campId, setId] = useState();
  const [showModal, setShowModal] = useState(false);

  const [profilepic, setProfilePic] = useState(null);
  const [micro, setMicro] = useState(false);
  const [nano, setNano] = useState(true);
  const [mega, setMega] = useState(false);
  const [any, setAny] = useState(false);
  const [name, setName] = useState("");
  const [mid, setMid] = useState(false);
  const [macro, setMacro] = useState(false);
  const [category, setCategory] = useState("");
  const [platform, setPlatform] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("Post");
  const [summary, setSummary] = useState("");
  const [url, setUrl] = useState("");
  const [requiredInfluencers, setRequiredInfluencers] = useState("");
  const [cpm, setCpm] = useState("");
  const dispatch = useDispatch();
  const [campaign_tracking_type, setCampaignTrackingType] = useState("");
  const [cover, setCover] = useState("");
  const [sample, setSample] = useState("");
  const [selectedImg1, setSelectedImg1] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [selectedImg2, setSelectedImg2] = useState("");
  const [previewSource2, setPreviewSource2] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [fileInputState2, setFileInputState2] = useState("");
  const [isBarterChecked, setIsBarterChecked] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productLink, setProductLink] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const companyId = Cookies.get("userid");
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  const [BusinessWalletBalance, setBusinessWalletBalance] = useState(0);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    const fetchWallet = async () => {
      const walletResponse = await ApiInterceptor.get(
        `businesswallet/getBusinessWalletByBusinessId/${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      setBusinessWalletBalance(walletResponse?.data?.currentBalance);
    };
    fetchWallet();
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);
  const oneDayAhead = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  };

  const [campEndDate, setCampEndDate] = useState("");
  const [wantEndDate, setWantEndDate] = useState(false);
  const [scheduleCampaign, setScheduleCampaign] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const handleBarterChange = () => {
    setIsBarterChecked(!isBarterChecked);
  };
  const handleFileInputChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      previewFile1(file);
      setSelectedImg1(file);
      setFileInputState1(e.target.value);
      // setDesc("");
      setLoading1(true);
      handleImage1(file);
    }
  };

  const handleFileInputChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      previewFile2(file);
      setSelectedImg2(file);
      setFileInputState2(e.target.value);
      setLoading2(true);
      handleImage2(file);
    }
  };

  const previewFile1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource1(reader.result);
    };
  };

  const previewFile2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource2(reader.result);
    };
  };

  const handleImage1 = (file) => {
    // e.preventDefault();
    console.log("hello");
    setSelectedImg1(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage1(file);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const handleImage2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      uploadImage2(file);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const uploadImage1 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading1(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState1("");
      // setPreviewSource1("");
      setCover(data.publicUrl);
      toast.success("Image uploaded");
    } catch (err) {
      setErrors(true);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setErrors(false);
      setLoading1(false);
    }
  };

  const uploadImage2 = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      setLoading2(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState2("");
      // setPreviewSource2("");
      setSample(data.publicUrl);
      toast.success("Image uploaded");
    } catch (err) {
      setErrors(true);
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setErrors(false);
      setLoading2(false);
    }
  };

  const currentUserId = Cookies.get("userid");
  const fetchUserData = async () => {
    try {
      // console.log(currentUserId);
      const { data } = await ApiInterceptor.get(
        `currentUser?userid=${currentUserId}`,
        config
      );
      // console.log("userpic", data);
      setEmail(data.email);
      setProfilePic(data?.image);
    } catch (error) {
      setErrors(true);
      dispatch(loginError());
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setErrors(false);
    }
  };

  useEffect(() => {
    setUserId(currentUserId);
    fetchUserData();
  }, []);

  const handleScheduleToggle = () => {
    setScheduleCampaign(!scheduleCampaign);
    if (scheduleCampaign) {
      setScheduleDate("");
      setScheduleTime("");
    }
  };
  // console.log(scheduleDate, scheduleTime, "scheduleDate, scheduleTime");

  const handleScheduleDateChange = (date) => {
    setScheduleDate(date);
  };

  const handleScheduleTimeChange = (time) => {
    setScheduleTime(time);
  };
  const handleEndDateToggle = () => {
    setWantEndDate(!wantEndDate);
    if (wantEndDate) {
      setCampEndDate("");
    }
  };

  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const [completedSteps, setCompletedSteps] = useState([]);
  const eachStepValidationCurrentState = [true, true, true, true];

  const [minimumFollowers, setMinimumFollowers] = useState(0);
  const [maximumFollowers, setMaximumFollowers] = useState(0);

  const validateStep1 = () => {
    let isValid = true;
    if (
      name.length < 2 ||
      name.trim().length === 0 ||
      name.trim().length == 0
    ) {
      toast.error("Please enter a relevant campaign name", {
        duration: 1000,
      });
      isValid = false;
      return isValid;
    }

    if (category.trim().length == 0) {
      toast.error("Please select a category", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (platform.trim().length == 0) {
      toast.error("Please select a platform", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (type.trim().length == 0 || !type) {
      toast.error("Please select a type", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  const validateStep2 = () => {
    let isValid = true;
    if (!url || url.trim().length == 0) {
      toast.error("Please enter a url", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (!url || url.trim().length == 0 || isValidUrl == false) {
      toast.error("Please enter a valid url", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (!summary || summary.trim().length == 0) {
      toast.error("Please provide instructions", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (!desc || desc.trim().length === 0) {
      toast.error("Please add the description", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (!cover) {
      toast.error("Please upload a cover image", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (!sample) {
      toast.error("Please upload a sample image", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  const validateStep3 = () => {
    let isValid = true;
    if (!requiredInfluencers || requiredInfluencers.trim().length == 0) {
      toast.error("Please enter required influencers number", {
        duration: 1000,
      });
      isValid = false;
      return isValid;
    }
    if (!cpm || cpm.trim().length == 0) {
      toast.error("Please enter cpm value", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (!campEndDate) {
      toast.error("Please add the campaign end date", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (scheduleCampaign === true && (!scheduleDate || !scheduleTime)) {
      toast.error("Please add the schedule date and time", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (new Date(campEndDate) < new Date(scheduleDate)) {
      toast.error("Campaign end date should be greater than schedule date", {
        duration: 1000,
      });
      isValid = false;
      return isValid;
    }

    if (campaign_tracking_type.trim().length == 0 || !campaign_tracking_type) {
      toast.error("Please select a tracking type", { duration: 1000 });
      isValid = false;
      return isValid;
    }
    if (isBarterChecked) {
      if (!productName || productName.trim().length == 0) {
        toast.error("Please enter product name", { duration: 1000 });
        isValid = false;
        return isValid;
      }
      if (!productPrice || productPrice.trim().length == 0) {
        toast.error("Please enter product price", { duration: 1000 });
        isValid = false;
        return isValid;
      }
      if (!productLink || productLink.trim().length == 0) {
        toast.error("Please enter product url", { duration: 1000 });
        isValid = false;
        return isValid;
      }
    }

    let followersLimit = 0;
    if (nano) followersLimit = 5000;
    else if (micro) followersLimit = 10000;
    else if (mega) followersLimit = 1000000;
    else if (mid) followersLimit = 50000;
    else if (macro) followersLimit = 500000;

    const minimumBalance = (cpm / 1000) * followersLimit * requiredInfluencers;

    if (BusinessWalletBalance < minimumBalance) {
      toast.error(
        `Insufficient Wallet Balance. To Create this Campaign the Minumum Wallet Balance should be ${minimumBalance}`,
        { duration: 5000 }
      );
      isValid = false;
      return isValid;
    }

    return isValid;
  };
  const handleActiveStep = (index) => {
    // setActiveStepIndex(index);
    if (index <= activeStepIndex || completedSteps.includes(index)) {
      setActiveStepIndex(index);
    } else {
      let isValid = true;
      if (activeStepIndex === 0) isValid = validateStep1();
      if (activeStepIndex === 1) isValid = validateStep2();
      if (activeStepIndex === 2) isValid = validateStep3();

      if (isValid) {
        setActiveStepIndex(index);
      } else {
        alert("Please fill in all required fields.");
      }
    }
  };
  const handleNext = () => {
    let isValid = true;
    if (activeStepIndex === 0) {
      isValid = validateStep1();
      eachStepValidationCurrentState[0] = isValid;
    }
    if (activeStepIndex === 1) {
      isValid = validateStep2();
      eachStepValidationCurrentState[1] = isValid;
    }
    if (activeStepIndex === 2) {
      isValid = validateStep3();
      eachStepValidationCurrentState[2] = isValid;
    }
    if (!isValid) {
      return;
    }
    setCompletedSteps([...completedSteps, activeStepIndex]);
    setActiveStepIndex(activeStepIndex + 1);
  };

  const handleBack = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };

  const formattedCampEndDate =
    campEndDate !== "" ? campEndDate.toISOString().split("T")[0] : "";
  const formattedSchduleDate =
    scheduleDate !== "" ? scheduleDate.toISOString().split("T")[0] : "";

  let formattedScheduleTime = "";

  if (scheduleTime) {
    const timeParts = scheduleTime
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .split(" ");

    if (timeParts.length === 2) {
      const correctTime = timeParts[0] + " " + timeParts[1];
      formattedScheduleTime = correctTime;
    }
  }
  const create = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      if (!eachStepValidationCurrentState[0]) {
        return;
      }
      if (!eachStepValidationCurrentState[1]) {
        return;
      }
      if (!eachStepValidationCurrentState[2]) {
        return;
      }

      let minfollowersLimit = 0;
      let maxfollowersLimit = 0;
      if (nano) {
        minfollowersLimit = 5000;
        maxfollowersLimit = 10000;
      } else if (micro) {
        minfollowersLimit = 10000;
        maxfollowersLimit = 50000;
      } else if (mega) {
        minfollowersLimit = 1000000;
        maxfollowersLimit = 20000000;
      } else if (mid) {
        minfollowersLimit = 50000;
        maxfollowersLimit = 500000;
      } else if (macro) {
        minfollowersLimit = 500000;
        maxfollowersLimit = 1000000;
      }

      //if there is campaign end date

      // const response = await axios.post(
      //   "https://api.cloutin.co/api/campaign",
      //   {
      //     name: name,
      //     company_mail: email,
      //     category: category,
      //     platform: platform,
      //     description: desc,
      //     type: type,
      //     tracking_type: campaign_tracking_type,
      //     // barter: barterDetails,
      //     barter: isBarterChecked,
      //     productName: productName,
      //     product_price: productPrice,
      //     product_link: productLink,
      //     minimumFollowers: minfollowersLimit,
      //     maximumFollowers: maxfollowersLimit,
      //     placement: "Placement",
      //     url: url,
      //     instruction: summary,
      //     requiredInfluencers: requiredInfluencers,
      //     cpm: cpm,
      //     cover_image_url: cover,
      //     sample_image_url: sample,
      //     cost: cpm,
      //     userId: userId,
      //     status: "Pending",
      //     Influencers: [],
      //     status_code: 200,
      //     campEndDate: formattedCampEndDate,
      //     scheduleCampaign: scheduleCampaign,
      //     scheduleDate: formattedSchduleDate,
      //     scheduleTime: formattedScheduleTime,
      //     // cost: cost,
      //   },
      //   config
      // );
      const response = await ApiInterceptor.post(
        "campaign",
        {
          name: name,
          company_mail: email,
          category: category,
          platform: platform,
          description: desc,
          type: type,
          tracking_type: campaign_tracking_type,
          // barter: barterDetails,
          barter: isBarterChecked,
          productName: productName,
          product_price: productPrice,
          product_link: productLink,
          minimumFollowers: minfollowersLimit,
          maximumFollowers: maxfollowersLimit,
          placement: "Placement",
          url: url,
          instruction: summary,
          requiredInfluencers: requiredInfluencers,
          cpm: cpm,
          cover_image_url: cover,
          sample_image_url: sample,
          cost: cpm,
          userId: userId,
          status: "Pending",
          Influencers: [],
          status_code: 200,
          campEndDate: formattedCampEndDate,
          scheduleCampaign: scheduleCampaign,
          scheduleDate: formattedSchduleDate,
          scheduleTime: formattedScheduleTime,
          // cost: cost,
        },
        config
      );
      // console.log(response.data);
      if (response?.status == 200) {
        ApiInterceptor.post("notifications/post", {
          data: {
            influencerId: userId,
            notificationTitle: "Campaign",
            notificationDesc: `${name} Campaign is created successfully`,
          },
        });
        dispatch(hideLoading());

        toast.success("Campaign created successfully");
        setShowModal(true);
        setId(response?.data?.id);
      } else {
        console.log(response);
        toast.error(response?.data?.error || "Something went wrong");
      }
    } catch (error) {
      // console.log(error);
      setErrors(true);
      if (error?.response?.data?.message === "Duplicate entry found!")
        toast("Campaign already exists with this data", { duration: 1000 });
      else console.log(error);
      toast.error(error?.response?.data?.error || "Something went wrong", {
        duration: 1000,
      });
    } finally {
      setErrors(false);
      dispatch(hideLoading());
    }
  };

  // console.log(campEndDate, "campEndDate hai bhai");

  const [isValidUrl, setIsValidUrl] = useState(true);

  const handleUrlChange = (event) => {
    const inputUrl = event.target.value;
    setUrl(inputUrl);
    const urlPattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    const isValid = urlPattern.test(inputUrl);
    setIsValidUrl(isValid);
  };

  // to make border transparent of material ui
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  };

  // const today = new Date();
  // const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
  // const formattedDate = today.toLocaleDateString("en-US", options);
  const today = new Date();
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Define a custom formatter function
  const customFormatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = customFormatter.format(today);

  // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split(" ");

  // Format the date as "31 March 2024"
  const finalFormattedDate = `${day}${month} ${year}`;

  // const currentUserId = Cookies.get("userid");
  const steps = [
    "Campaign Detail",
    "Promotional Info",
    "Budget and Bids",
    "Overview",
  ];

  const [openDisablePageModal, setOpenDisablepageModal] = useState(false);
  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablepageModal(true);
    }
  }, [userAccountStatus]);

  const handleMinimum = () => {
    let min = 0;
    if (nano) {
      min = 5000;
    } else if (micro) {
      min = 10000;
    } else if (mega) {
      min = 1000000;
    } else if (mid) {
      min = 50000;
    } else if (macro) {
      min = 500000;
    }

    //  setMinimumFollowers(min);

    if (min == 0 || !requiredInfluencers) return NaN;
    return min * requiredInfluencers;
  };
  const handleMaximum = () => {
    let max = 0;
    if (nano) {
      max = 10000;
    } else if (micro) {
      max = 50000;
    } else if (mega) {
      max = 20000000;
    } else if (mid) {
      max = 500000;
    } else if (macro) {
      max = 1000000;
    }

    //  setMaximumFollowers(max);

    if (max == 0 || !requiredInfluencers) return NaN;
    return max * requiredInfluencers;
  };

  const threeDaysAhead = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date;
  };

  return (
    <>
      <div className="flex flex-col">
        {errors && <ErrorPage />}
        <div className="w-[1000px] m-auto main-stepper">
          <Box sx={{ width: "100%" }} className="stepper-res">
            <Stepper activeStep={activeStepIndex} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    onClick={() => handleActiveStep(index)}
                    // className="!cursor-pointer"
                    className={
                      index > activeStepIndex
                        ? "cursor-pointer pointer-events-none"
                        : "cursor-pointer"
                    }
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
        <div className="flex mt-3 community">
          <div className="w-[500px] bg-[#f8f8f8] p-[20px] md:p-2 overflow-y-scroll lg:h-[calc(100vh-102px)] ">
            <div className="md:w-full md:p-[15px]">
              <form onSubmit={create}>
                {activeStepIndex === 0 && (
                  <div className="step-1-create">
                    <h1 className=" mb-4 font-extrabold">
                      Create Your First Campaign
                    </h1>

                    <TextField
                      className=" bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none  font-poppins  hover:border hover:border-black  focus:border focus:border-black rounded-[10px] 4k:h-[5vh] 4k:text-2xl"
                      label="Campaign Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{ style: inputStyles }}
                    ></TextField>

                    <div className="w-full mb-[10px] text-[#b2aeae] flex justify-between space-x-[20px]">
                      <div className=" my-4 w-[50%]">
                        <FormControl style={{ background: "white" }} fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Select Category"
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            {categories.map((category) => (
                              <MenuItem value={category}>{category}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className=" my-4 w-[50%]">
                        <FormControl style={{ background: "white" }} fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Platform
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={platform}
                            label="Select Platform"
                            onChange={(e) => {
                              setPlatform(e.target.value);
                              if (e.target.value === "Youtube") {
                                setType("Video");
                              } else {
                                setType("Post");
                              }
                            }}
                          >
                            <MenuItem value={"Instagram"}>Instagram</MenuItem>
                            <MenuItem value={"Youtube"}>Youtube</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <h1 className="font-bold">Enter Campaign Type</h1>
                    {!(platform === "Youtube") ? (
                      <div className="w-full my-[10px] flex justify-between">
                        <h1
                          className={
                            !(type === "Post")
                              ? "md:px-10 px-8 cursor-pointer py-2 border border-[#0082cc] shadow-inner bg-gray-50 text-black rounded-3xl mr-2"
                              : "md:px-10 px-8 cursor-pointer py-2 bg-[#0082cc] rounded-3xl text-white mr-2"
                          }
                          onClick={() => {
                            setType("Post");
                          }}
                        >
                          Post
                        </h1>
                        <h1
                          className={
                            !(type === "Story")
                              ? "md:px-10 px-8 cursor-pointer py-2 border border-[#0082cc] shadow-inner bg-gray-50 text-black rounded-3xl mr-2"
                              : "md:px-10 px-8 cursor-pointer py-2 bg-[#0082cc] rounded-3xl text-white mr-2"
                          }
                          onClick={() => {
                            setType("Story");
                          }}
                        >
                          Story
                        </h1>
                        <h1
                          className={
                            !(type === "Reels")
                              ? "md:px-10 px-8 cursor-pointer py-2 border border-[#0082cc] shadow-inner bg-gray-50 text-black rounded-3xl mr-2"
                              : "md:px-10 px-8 cursor-pointer py-2 bg-[#0082cc] rounded-3xl text-white mr-2"
                          }
                          onClick={() => {
                            setType("Reels");
                          }}
                        >
                          Reels
                        </h1>
                      </div>
                    ) : (
                      <div div className="w-full my-[10px] flex">
                        <h1
                          className={
                            !(type === "Video")
                              ? "md:px-10 px-8 cursor-pointer py-2 border border-blue-300 shadow-inner bg-gray-50 text-black rounded-3xl  mr-2"
                              : "md:px-10 px-8 cursor-pointer py-2 bg-blue-500 rounded-3xl text-white mr-2"
                          }
                          onClick={() => {
                            setType("Video");
                          }}
                        >
                          Video
                        </h1>
                        <h1
                          className={
                            !(type === "Shorts")
                              ? "md:px-10 px-8 cursor-pointer py-2 border border-blue-300  rounded-3xl shadow-inner bg-gray-50 text-black"
                              : "md:px-10 px-8 cursor-pointer py-2 bg-blue-500 rounded-3xl text-white"
                          }
                          onClick={() => {
                            setType("Shorts");
                          }}
                        >
                          Shorts
                        </h1>
                      </div>
                    )}

                    <p className="text-gray-600 pl-2 text-[11px] w-full">
                      Post Description: (may contain product
                      description,hashtags,link to insta pages)
                    </p>

                    <div className="mt-3 flex justify-end">
                      <button
                        onClick={handleNext}
                        className="px-2 py-2 bg-blue-500 text-white rounded-md"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {activeStepIndex === 1 && (
                  <div className="step-2-create">
                    <h1 className="font-bold my-[10px]">Redirect url</h1>
                    <input
                      className="pl-6 border border-[#0082cc] placeholder-[#0082cc]  bg-transparent rounded-3xl text-[#0082cc] p-3 w-full focus:outline-none"
                      type="text"
                      placeholder="Link in bio or swipe up url(story)"
                      value={url}
                      onChange={handleUrlChange}
                    />
                    {!isValidUrl && (
                      <div style={{ color: "red" }}>Invalid website URL</div>
                    )}

                    <h1 className="font-bold my-[10px]">
                      Instructions for influencers
                    </h1>
                    <textarea
                      className="w-full pl-4 focus:outline-none p-3 border border-blue-500 shadow-md rounded-md resize-none text-gray-700"
                      placeholder="✍ Instructions for the influencers"
                      rows={6}
                      value={summary}
                      onChange={(e) => setSummary(e.target.value)}
                    />
                    <h1 className="my-[10px] pb-1 font-bold">Description</h1>
                    <textarea
                      name="desc"
                      id="desc"
                      placeholder="Add some more detail"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      rows={5}
                      className="text-[14px] w-full p-3 border border-blue-500  rounded-md resize-none"
                    ></textarea>
                    <h1 className="font-bold mb-[10px] mt-4">
                      Drop Your Images{" "}
                    </h1>
                    <div className="flex justify-center space-x-8">
                      <div className="shadow-lg bg-white px-5 py-[10px] border border-gray-500 rounded-md">
                        {!loading1 ? (
                          <label
                            className="flex items-center flex-col"
                            htmlFor="pt1"
                          >
                            <img
                              className="w-32 h-24 cursor-pointer"
                              src={cover ? cover : "/Images/cover.png"}
                              alt="drop"
                            />
                            {selectedImg1 && cover && (
                              <h1>{!loading1 ? "Uploaded" : "Uploading"}</h1>
                            )}
                          </label>
                        ) : (
                          <SpinnerCircular
                            size="90"
                            className="w-full flex items-center mx-auto"
                            thickness="100"
                            speed="600"
                            color="white"
                            secondaryColor="black"
                          />
                        )}
                        <input
                          accept="image/png, image/jpeg, image/jpg"
                          id="pt1"
                          type="file"
                          hidden
                          // value={fileInputState1||''}
                          onChange={handleFileInputChange1}
                        />
                      </div>
                      <div className="shadow-lg bg-white px-5 py-[10px] border border-gray-500 rounded-md">
                        {!loading2 ? (
                          <label
                            className="flex items-center flex-col"
                            htmlFor="pt2"
                          >
                            <img
                              className="w-32 h-24 cursor-pointer"
                              src={sample ? sample : "/Images/upl.png"}
                              alt="drop"
                            />
                            {selectedImg2 && sample && (
                              <h1>{!loading2 ? "Uploaded" : "Uploading"}</h1>
                            )}
                          </label>
                        ) : (
                          <SpinnerCircular
                            size="90"
                            className="w-full flex items-center mx-auto"
                            thickness="100"
                            speed="600"
                            color="white"
                            secondaryColor="black"
                          />
                        )}

                        <input
                          id="pt2"
                          accept="image/png, image/jpeg, image/jpg"
                          type="file"
                          hidden
                          onChange={handleFileInputChange2}
                        />
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end gap-4">
                      <button
                        onClick={handleBack}
                        className="px-2 py-2 bg-blue-500 text-white rounded-md"
                      >
                        Prev
                      </button>
                      <button
                        onClick={handleNext}
                        className="px-2 py-2 bg-blue-500 text-white rounded-md"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {activeStepIndex === 2 && (
                  <div className="step-2-create">
                    <h1 className="font-bold my-[10px]">
                      Select Followers Limits
                    </h1>
                    <div className="flex flex-wrap gap-[10px]">
                      <h1
                        className={
                          !nano
                            ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-[#0082cc] rounded-3xl"
                            : "text-white  cursor-pointer px-[9px] w-fit py-2 bg-[#0082cc] rounded-3xl"
                        }
                        onClick={() => {
                          setNano(!nano);
                          setMacro(false);
                          setAny(false);
                          setMega(false);
                          setMicro(false);
                          setMid(false);
                        }}
                      >
                        Nano Influencers: 5K - 10K
                      </h1>
                      <h1
                        className={
                          !micro
                            ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-[#0082cc] rounded-3xl"
                            : "text-white cursor-pointer px-[9px] w-fit py-2 bg-[#0082cc] rounded-3xl"
                        }
                        onClick={() => {
                          setMicro(!micro);
                          setMacro(false);
                          setAny(false);
                          setMega(false);
                          setNano(false);
                          setMid(false);
                        }}
                      >
                        Micro: 10K - 50K
                      </h1>
                      <h1
                        className={
                          !mega
                            ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-[#0082cc] rounded-3xl"
                            : "text-white cursor-pointer px-[9px] w-fit py-2 bg-[#0082cc] rounded-3xl"
                        }
                        onClick={() => {
                          setMega(!mega);
                          setMacro(false);
                          setAny(false);
                          setNano(false);
                          setMicro(false);
                          setMid(false);
                        }}
                      >
                        Mega: 1M - 20M
                      </h1>
                      <h1
                        className={
                          !mid
                            ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-[#0082cc] rounded-3xl"
                            : "text-white cursor-pointer px-[9px] w-fit py-2 bg-[#0082cc] rounded-3xl"
                        }
                        onClick={() => {
                          setMid(!mid);
                          setMacro(false);
                          setAny(false);
                          setMega(false);
                          setMicro(false);
                          setNano(false);
                        }}
                      >
                        Mid Tier: 50K - 500K
                      </h1>
                      <h1
                        className={
                          !macro
                            ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-[#0082cc] rounded-3xl"
                            : "text-white cursor-pointer px-[9px] w-fit py-2 bg-[#0082cc] rounded-3xl"
                        }
                        onClick={() => {
                          setMacro(!macro);
                          setNano(false);
                          setAny(false);
                          setMega(false);
                          setMicro(false);
                          setMid(false);
                        }}
                      >
                        Macro: 500K - 1M
                      </h1>
                      <h1
                        className={
                          !any
                            ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-[#0082cc] rounded-3xl"
                            : "text-white cursor-pointer px-[9px] w-fit py-2 bg-[#0082cc] rounded-3xl"
                        }
                        onClick={() => {
                          setAny(!any);
                          setMacro(false);
                          setNano(false);
                          setMega(false);
                          setMicro(false);
                          setMid(false);
                        }}
                      >
                        Get any followers
                      </h1>
                    </div>
                    <h1 className="text-gray-700 my-[10px] py-2 bg-white text-center shadow-md rounded-full">
                      Influencers Found:
                      <span className="font-bold text-blue-500"> 5000</span>
                    </h1>
                    <h1 className="font-bold">Influencers Required</h1>
                    <input
                      className="text-[#b2aeae] focus:outline-none w-full my-[10px] py-2 bg-white pl-[10px] border border-[#0082cc] shadow-md rounded-md"
                      type="text"
                      placeholder="Drop the value in Numeric"
                      value={requiredInfluencers}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          setRequiredInfluencers(value);
                        }
                      }}
                      pattern="\d*"
                      min={0}
                    />

                    <div className="flex items-center">
                      <h1 className="font-bold mt-[10px]">CPM</h1>
                      <div className="relative inline-block">
                        <div className="ml-2 relative group mt-2">
                          <FaInfoCircle className="text-blue-500 w-6 h-6 mt-1 cursor-pointer" />
                          <div className=" text-center absolute left-1/2 transform -translate-x-1/6 mt-2 w-64 p-2 bg-white border border-gray-300 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300 z-10">
                            CPM depicts the cost per 1000 followers
                          </div>
                        </div>
                      </div>
                    </div>

                    <input
                      type="text"
                      className="text-[#b2aeae] focus:outline-none w-full mt-[10px] py-2 bg-white pl-[10px] border border-[#0082cc] shadow-md rounded-md"
                      placeholder="CPM (Cost per 1000 followers)"
                      value={cpm}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          setCpm(value);
                        }
                      }}
                      min={1}
                    />
                    <div className="h-[1px] w-full bg-gray-300 mt-4"></div>
                    <div className="flex  gap-2 flex-wrap items-center pt-4">
                      <div>
                        <div className="flex gap-2 flex-wrap">
                          <h1 className="text-black font-bold">
                            Campaign End Date:
                          </h1>
                          <DatePicker
                            selected={campEndDate}
                            minDate={threeDaysAhead()}
                            onChange={(date) => {
                              setCampEndDate(date);
                            }}
                            className="border rounded-lg p-2"
                            placeholderText="Select end date for the campaign"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-2 flex-wrap items-center pt-4">
                      <h1 className="text-black font-semibold">
                        Would you like to schedule this campaign?
                      </h1>
                      <input
                        type="checkbox"
                        checked={scheduleCampaign}
                        onChange={handleScheduleToggle}
                        className="h-6 w-6 rounded-md"
                      />
                    </div>

                    {scheduleCampaign && (
                      <div>
                        <div className="flex gap-2 flex-wrap">
                          <h1 className="text-cyan-900">Schedule Date:</h1>
                          <DatePicker
                            selected={scheduleDate}
                            // onChange={handleScheduleDateChange}
                            onChange={
                              (date) => {
                                setScheduleDate(date);
                              }
                              // console.log(scheduleDate, "scheduleDate")
                            }
                            minDate={oneDayAhead()}
                            className="border rounded-lg p-2"
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select date for the campaign"
                          />
                        </div>

                        <div className="flex gap-2 flex-wrap">
                          <h1 className="text-cyan-900">Schedule Time:</h1>
                          <DatePicker
                            selected={scheduleTime}
                            onChange={handleScheduleTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className="border rounded-lg p-2"
                            placeholderText="Select time for the campaign"
                          />
                        </div>
                      </div>
                    )}

                    <div className="h-[1px] w-full bg-white border border-gray mt-4"></div>

                    <h1 className="font-bold mt-[10px]">
                      Campaign Tracking Type
                    </h1>
                    <select
                      className="w-full py-2 pl-6 bg-white border border-blue-400 rounded-md  mt-[10px] focus:outline-none"
                      onChange={(e) => {
                        setCampaignTrackingType(e.target.value);
                      }}
                      value={campaign_tracking_type}
                    >
                      <option hidden={true} className="bg-white">
                        Select Campaign Tracking Type
                      </option>
                      <option value="Brand awareness">Brand awareness</option>
                      <option value="Conversions">Conversions</option>
                      {/* <option value="Website Traffic">Website Traffic</option> */}
                    </select>
                    <label>
                      <input
                        className="mt-5 mb-5"
                        type="checkbox"
                        checked={isBarterChecked}
                        onChange={handleBarterChange}
                      />
                      <t /> Barter Checkbox
                    </label>

                    {isBarterChecked && (
                      <div className="flex flex-col">
                        <h1 className="font-bold mt-[10px]">Product Name</h1>
                        <input
                          type="text"
                          placeholder="Product Name"
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                          className="text-[#b2aeae] focus:outline-none w-full mt-[10px] py-2 bg-white pl-[10px] border border-[#0082cc] shadow-md rounded-md"
                        />
                        <h1 className="font-bold mt-[10px]">Product Price</h1>
                        <input
                          type="number"
                          placeholder="Product Price"
                          value={productPrice}
                          onChange={(e) => setProductPrice(e.target.value)}
                          className="text-[#b2aeae] focus:outline-none w-full mt-[10px] py-2 bg-white pl-[10px] border border-[#0082cc] shadow-md rounded-md"
                        />
                        <h1 className="font-bold mt-[10px]">Product Link</h1>
                        <input
                          type="text"
                          placeholder="Product Link"
                          value={productLink}
                          onChange={(e) => setProductLink(e.target.value)}
                          className="text-[#b2aeae] focus:outline-none w-full mt-[10px] py-2 bg-white pl-[10px] border border-[#0082cc] shadow-md rounded-md"
                        />
                      </div>
                    )}
                    <div className="">
                      <h1 className="font-bold">Summary</h1>
                    </div>
                    <div className="flex justify-between items-center w-full mt-[10px] mb-[10px]">
                      <h1 className="text-[#b2aeae]">
                        Minimum Followers Reach
                      </h1>
                      <h1 className="font-bold  text-end">{handleMinimum()}</h1>
                    </div>
                    <div className="flex justify-between items-center w-full mt-[10px] mb-[10px]">
                      <h1 className="text-[#b2aeae]">
                        Maximum Followers Reach
                      </h1>
                      <h1 className="font-bold  text-end">{handleMaximum()}</h1>
                    </div>

                    <div className="mt-4 flex justify-end gap-4">
                      <button
                        onClick={handleBack}
                        className="px-2 py-2 bg-blue-500 text-white rounded-md"
                      >
                        Prev
                      </button>
                      <button
                        onClick={handleNext}
                        className="px-2 py-2 bg-blue-500 text-white rounded-md"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
                {activeStepIndex === 3 && (
                  <div className="step-4-create">
                    <div className="shadow-md rounded-md border-l-2 border-blue-300 py-0 px-2  bg-white pt-2 flex flex-col justify-start gap-3 ">
                      <h1 className="text-gray-700 text-2xl font-semibold">
                        Brief Outlook
                      </h1>
                      <div>
                        <button
                          className="flex gap-2 text-xl items-center underline text-blue-500"
                          onClick={handleBack}
                        >
                          <IoMdReturnLeft /> Back
                        </button>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">Campaign Name &rarr;</h1>
                        <span className="">{name}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">Category &rarr;</h1>
                        <span className="">{category}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">Plateform &rarr;</h1>
                        <span className="">{platform}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">Campaign Type &rarr;</h1>
                        <span className="">{type}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">Redirect URL &rarr;</h1>
                        <span>{url}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">
                          Influencers Required &rarr;
                        </h1>
                        <span>{requiredInfluencers}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">CPM &rarr;</h1>
                        <span>{cpm}</span>
                      </div>
                      <div className="flex  gap-2 flex-wrap">
                        <h1 className="text-cyan-900">
                          Campaign Tracking Type &rarr;
                        </h1>
                        <span>{campaign_tracking_type}</span>
                      </div>

                      <div className="flex gap-2 flex-wrap">
                        <h1 className="text-cyan-900">
                          Campaign End Date &rarr;
                        </h1>
                        <span>
                          {campEndDate
                            ? campEndDate?.toDateString()
                            : "Not Set Yet"}
                        </span>
                      </div>

                      <div className="flex gap-2 flex-wrap">
                        <h1 className="text-cyan-900">
                          Campaign Scheduled Date &rarr;
                        </h1>
                        <span>
                          {scheduleDate
                            ? scheduleDate.toDateString()
                            : "Not Set Yet"}
                        </span>
                      </div>

                      <div>
                        <button
                          className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-[#0082cc]"
                          type="submit"
                        >
                          {loading ? "Creating..." : "Create Campaign"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
              <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                campId={campId}
                url={`https://app.cloutin.co/campaign/${campId}`}
              />
            </div>
          </div>

          {/* mckupscreen */}
          <div class="relative mx-auto border-gray-800   border-[14px] rounded-[2.5rem] h-[600px] w-[300px] ">
            <div class="h-[32px] w-[3px] bg-gray-800  absolute -start-[17px] top-[72px] rounded-s-lg"></div>
            <div class="h-[46px] w-[3px] bg-gray-800  absolute -start-[17px] top-[124px] rounded-s-lg"></div>
            <div class="h-[46px] w-[3px] bg-gray-800  absolute -start-[17px] top-[178px] rounded-s-lg"></div>
            <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
            <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white ">
              <div className="flex gap-2 flex-col">
                <div className="mt-2 flex justify-between mx-3">
                  <GoArrowLeft />
                  <h1 className="text-center  text-black">Campaign Details</h1>
                  <IoMdShare />
                </div>
                <div className="mx-2 border-2 border-white rounded-md">
                  <img
                    className=" rounded-md w-40 h-40 object-cover m-auto "
                    src={
                      previewSource1
                        ? previewSource1
                        : "https://www.pixelstalk.net/wp-content/uploads/2016/07/Wallpapers-pexels-photo.jpg"
                    }
                    alt="image"
                  />
                </div>
                <div className="flex items-center justify-between flex-wrap mx-2">
                  <h2 className="text-[14px]">
                    Amount :{" "}
                    <span className="text-green-500">
                      {cpm ? (cpm * 2000) / 1000 : "0"}
                    </span>
                  </h2>
                  <h2 className="text-[14px]">
                    Followers Required:{requiredInfluencers}
                  </h2>
                </div>
                <div className="flex justify-between flex-row flex-wrap mx-4">
                  <div className="flex ">
                    <img src="" alt="" />
                    <div>
                      <h1 className="text-[12px]">{name}</h1>
                      <span className="text-[10px]">{formattedDate}</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[2px] text-[12px]">
                    <span>Campaing Type</span>
                    <span>{type}</span>
                  </div>
                </div>
                <div className="flex items-center flex-row gap-[6px] mx-3 md:flex-wrap">
                  <span className="text-black text-[13px]">Categories:</span>
                  <span className="bg-white shadow-md rounded-full py-[2px] border border-blue-300  px-2 text-[12px] ">
                    {category.length > 0 ? category : "Sports"}
                  </span>
                </div>
                <div className="flex justify-start items-center gap-3 mx-3 flex-wrap">
                  <span className="text-gray-800 text-[12px]">Plateform:</span>
                  <span className=" py-1  px-2 text-[12px]">
                    {platform.length > 0 ? (
                      platform === "Instagram" ? (
                        <IoLogoInstagram color="blue" />
                      ) : (
                        <FaYoutube color="red" />
                      )
                    ) : (
                      <IoLogoInstagram color="blue" />
                    )}
                  </span>
                </div>
                <div className="flex justify-start mx-3 flex-col gap-[2px]">
                  <div className="flex flex-col">
                    <h4 className="text-[12px]">Post Description</h4>
                    <p className="bg-white rounded-md border border-blue-400  shadow-md px-2 text-gray-700 h-[56px] break-words text-ellipsis line-clamp-3 text-[10px]">
                      {summary.length === 0 && "Post Description"}
                      {summary.length > 80
                        ? summary.slice(0, 80) + "..."
                        : summary}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h4 className="text-[12px]">Instructions for campaign</h4>
                    <p className="bg-white rounded-md border border-blue-400  shadow-md px-2 text-gray-700 h-[56px] break-words text-ellipsis line-clamp-3 text-[10px]">
                      {desc.length === 0 && "Add some more detail"}
                      {desc.length > 80 ? desc.slice(0, 80) + "..." : desc}
                    </p>
                  </div>
                  <div className="flex justify-start gap-2 mt-2">
                    <button className="flex items-center text-[12px] px-[3px] bg-blue-500 text-white rounded-full py-[2px]">
                      Copy Link <IoCopyOutline className="ml-[2px]" />
                    </button>
                    <button className="flex items-center text-[12px] px-[3px] bg-blue-500 text-white rounded-full py-[2px]">
                      Download Assets <MdFileDownload className="ml-[2px]" />
                    </button>
                  </div>
                  <div className="mt-[3px] flex items-center justify-center">
                    <button className="bg-blue-500 rounded-full px-2 py-[2px] text-white">
                      Apply for Campaign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverlayDisableAccountPage isOpen={openDisablePageModal} />
      <NetworkStatusOverlay />
    </>
  );
}

export default CreateCampaign;
