import React, { useState, useEffect } from "react";
import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { hideLoading, showLoading } from "../redux/Slice/loadingSlice";
import { useGoogleLogin } from "react-oauth-google";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loginError, loginStart, loginSuccess } from "../redux/Slice/userSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import GoogleButton from "react-google-button";
import { FcGoogle } from "react-icons/fc";
import ApiInterceptor from "../utils/ApiInterceptor";
const Login = () => {
  const [tokenl, setTokenl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const { loading } = useSelector((state) => state.loading);
  const flag = 1;

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/maindashboard");
    }
  }, []);

  useEffect(() => {
    if (tokenl !== "") {
      // Cookies.set("token",tokenl);
      // navigate("/dashboard");
      loginGoogleUser();
    }
  }, [tokenl]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const loginUser = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    dispatch(showLoading());
    try {
      if (username === "" && password === "") {
        dispatch(loginError());
        toast.error("Username and Password is required", {
          duration: 1000,
          position: "top-center",
        });
        dispatch(hideLoading());
        return;
      }
      if (username === "" || username.trim() === "") {
        dispatch(loginError());
        toast.error("Username is required", {
          duration: 1000,
          position: "top-center",
        });
        dispatch(hideLoading());
        return;
      }
      if (password === "" || password.trim() === "") {
        dispatch(loginError());
        toast.error("Password is required", {
          duration: 1000,
          position: "top-center",
        });
        dispatch(hideLoading());
        return;
      }



     
      if (!captchaValue) {
        dispatch(loginError());
         toast.error("Captcha Verification is required", {
          duration: 1000,
          position: "top-center",
        });
        dispatch(hideLoading());
        return;
      }



      const response = await ApiInterceptor.post("verify-captcha", {
        token: captchaValue,
      });
  

   if(response.status === 200){


      const { data } = await ApiInterceptor.post("company/login", {
        username,
        password,
      });

      if (data?.message === "No such Business User found") {
        navigate("/register");
      } else {
        if (data.message === "Password Incorrect") {
          toast.error("Incorrect Password");
          navigate("/login");
        } else {
          localStorage.setItem("token", data.token);
          // save local storage token  with a different name to avoid conflict with
          localStorage.setItem("cloutin", data.token);
          Cookies.set("username", data.username, { expires: 30, path: "/" });
          Cookies.set("name", data.name, { expires: 30, path: "/" });
          Cookies.set("token", data.token, { expires: 30, path: "/" });
          Cookies.set("userid", data.id, { expires: 30, path: "/" });
          Cookies.set("image", data.image, { expires: 30, path: "/" });

          dispatch(loginSuccess(data));

          setUsername("");
          setPassword("");
          // navigate("/");
          navigate("/maindashboard");
          toast.success("Login successfully ");
          dispatch(hideLoading());
        }
      }
    }
    else{
      toast.error("Captcha Verification Failed");
    }
    } catch (err) {
      dispatch(loginError());
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        duration: 2000,
      });
      dispatch(hideLoading());
    } finally {
      dispatch(hideLoading());
    }
  };
  const loginGoogleUser = async (e) => {
    try {
      const { data } = await ApiInterceptor.post(
        "company/googleAuth",
        // "https://api.cloutin.co/api/company/googleAuth",
        {
          accessToken: tokenl?.access_token,
          scope: tokenl?.scope,
          tokenType: tokenl?.tokenType,
          flag,
        }
      );

      if (data?.message === "No such Business User found") {
        toast.error("You are not registered yet. Please register first.", {
          duration: 2000,
          position: "top-center",
        });
        navigate({
          pathname: "/googleRegister",
          search: createSearchParams({
            accessToken: tokenl.access_token,
            flag: flag,
          }).toString(),
        });
      } else if (data?.msg === "Already Exists!!") {
        toast.error(
          "This Email is already Registered.Please use another Google Account",
          {
            duration: 2000,
            position: "top-center",
          }
        );
      } else {
        Cookies.set("username", data?.username, { expires: 30, path: "/" });
        Cookies.set("name", data?.name, { expires: 30, path: "/" });
        Cookies.set("token", data?.token, { expires: 30, path: "/" });
        // Cookies.set("token",data.token);
        Cookies.set("image", data?.image, { expires: 30, path: "/" });
        Cookies.set("userid", data?.id, { expires: 30, path: "/" });
        localStorage.setItem("token", data?.token);

        dispatch(loginSuccess(data));
        navigate("/maindashboard");
      }
    } catch (err) {
      return toast.error(
        err?.response?.data?.message || "Something went wrong",
        {
          duration: 1000,
        }
      );
    }
  };
  const loginGoogle = useGoogleLogin({
    clientId:
      "1052522319901-mqq8ekmtdvptehi8039bb9pp11og7kgh.apps.googleusercontent.com",
    redirectUri: "https://app.cloutin.co",
    scopes: ["profile", "email"], // Specify the required scopes
    onSuccess: (tokenResp) => {
      // console.log("Successfully logged in:", tokenResp);
      // console.log("token: ", tokenResp);
      setTokenl((tokenl) => tokenResp);
      // console.log(tokenl);
    },
  });

  return (
    // <div className="min-h-screen flex items-center justify-center bg-gray-100">
    //   <div className="flex w-full max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
    //     {/* Left Section */}
    //     <div className="hidden md:flex w-1/2 bg-gradient-to-br from-blue-500 to-indigo-600 p-8 flex-col justify-center">
    //       <div className="flex flex-col items-center text-center">
    //         {/* <img src={"collabb"} alt="cloutin.co" className="w-32 mb-4" /> */}
    //         <h1 className="text-white text-3xl mb-4">Welcome to Cloutin</h1>
    //         <ul className="text-white text-lg space-y-4">
    //           <li className="flex items-center">
    //             <span className="mr-2">✓</span> Campaign Applications
    //           </li>
    //           <li className="flex items-center">
    //             <span className="mr-2">✓</span> Direct Marketplace Orders
    //           </li>
    //           <li className="flex items-center">
    //             <span className="mr-2">✓</span> Affiliate Campaigns
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     {/* Right Section */}
    //     <div className="w-full md:w-1/2 p-8">
    //       <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">
    //         Login to Your Account
    //       </h2>
    //       <form onSubmit={loginUser}>
    //         <div className="mb-4">
    //           <label htmlFor="username" className="block text-gray-700">
    //             Username
    //           </label>
    //           <input
    //             type="text"
    //             id="username"
    //             className="mt-1 block w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    //             name="username"
    //             value={username}
    //             onChange={(e) => setUsername(e.target.value)}
    //           />
    //         </div>
    //         <div className="mb-4">
    //           <label htmlFor="password" className="block text-gray-700">
    //             Password
    //           </label>
    //           <div className="relative w-full">
    //             <input
    //               type={showPassword ? "text" : "password"}
    //               id="password"
    //               className="mt-1 block w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    //               name="password"
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //             />
    //             <button
    //               type="button"
    //               className="absolute inset-y-0 right-0 flex items-center px-3 bg-transparent focus:outline-none"
    //               onClick={togglePasswordVisibility}
    //             >
    //               {showPassword ? <FaEyeSlash /> : <FaEye />}
    //             </button>
    //           </div>
    //         </div>
    //         <div className="mb-4">
    //           <ReCAPTCHA
    //             sitekey="6LdZ7ewpAAAAAOslWJq1oC_cPdbpPbforwzWcJjl"
    //             onChange={handleCaptchaChange}
    //           />
    //         </div>
    //         <div className="mb-4 flex items-center justify-between">
    //           <Link
    //             to="/forgot-password"
    //             className="text-sm text-blue-500 hover:underline"
    //           >
    //             Forgot your password?
    //           </Link>
    //         </div>
    //         <button
    //           type="submit"
    //           className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-700"
    //         >
    //           {loading ? "Wait....You are on the way" : "Sign In"}
    //         </button>
    //       </form>
    //       <div className="mt-4 text-center text-xl">Or</div>
    //       <span className="w-full mt-2 py-2 px-4 flex items-center justify-center text-white font-semibold rounded-md focus:outline-none ">
    //         {/* <GoogleButton
    //           onClick={() => {
    //             loginGoogle();
    //           }}
    //         /> */}
    //         <div
    //           className="border-2 border-[#4285F4] flex items-center"
    //           onClick={() => {
    //             loginGoogle();
    //           }}
    //         >
    //           <div className="bg-white p-1 cursor-pointer px-2">
    //             <FcGoogle size={24} />
    //           </div>
    //           <button className="flex items-center justify-center space-x-2 bg-[#4285F4] text-white font-semibold py-2 px-4 rounded-none border-2 border-[#4285F4] hover:bg-[#357AE8] focus:outline-none focus:ring-2 focus:ring-[#357AE8] focus:ring-opacity-50">
    //             <span>LogIn / SignUp with Google</span>
    //           </button>
    //         </div>
    //       </span>
    //       <div className="mt-4 text-center">
    //         <span className="text-gray-600">Don't have an account?</span>
    //         <Link
    //           to="/register"
    //           className="ml-2 text-blue-500 font-semibold hover:underline"
    //         >
    //           Sign up with email
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="hidden md:flex md:w-1/2 lg:w-1/3 bg-gradient-to-b from-indigo-800 to-purple-700 text-white p-8 flex-col justify-center items-center md:items-start">
        <div className="mb-8 text-center md:text-left">
          <h1 className="text-4xl md:text-5xl font-bold mb-2">Cloutin</h1>
          <p className="text-gray-300">
            Marketplace, Influencer, and Campaigns
          </p>
        </div>
        <div className="max-w-md">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Features</h2>
          <ul className="list-disc pl-4 text-lg">
            <li className="mb-2">Seamless marketplace experience</li>
            <li className="mb-2">Connect with top influencers</li>
            <li className="mb-2">Powerful campaign management tools</li>
          </ul>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex-1 bg-white p-8 flex flex-col justify-center">
        <div className="max-w-md w-full mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
            Login to Cloutin
          </h2>

          <form onSubmit={loginUser} className="space-y-4">
            <div className="flex flex-col">
              <label
                htmlFor="username"
                className="block mb-2 font-bold text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="flex flex-col relative">
              <label
                htmlFor="password"
                className="block mb-2 font-bold text-gray-700"
              >
                Password
              </label>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 pr-10"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3 bg-transparent focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            <div className="flex justify-center">
              <ReCAPTCHA
                sitekey="6LdZ7ewpAAAAAOslWJq1oC_cPdbpPbforwzWcJjl"
                onChange={handleCaptchaChange}
              />
            </div>

            <div className="flex justify-between items-center">
              <Link
                to="/forgot-password"
                className="text-sm text-blue-500 hover:underline"
              >
                Forgot your password?
              </Link>
            </div>

            <button
              type="submit"
              className="bg-indigo-600 text-white rounded-md px-4 py-2 hover:bg-indigo-700 transition-colors duration-300 w-full font-bold"
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>

          <div className="mt-4 text-center">
            <span className="text-gray-600">Or</span>
          </div>

          <div className="mt-4">
            <button
              onClick={loginGoogle}
              className="flex items-center justify-center w-full border border-gray-300 rounded-md px-4 py-2 text-gray-700 hover:bg-gray-50 transition-colors duration-300"
            >
              <FcGoogle className="mr-2" size={20} />
              <span>Login with Google</span>
            </button>
          </div>

          <div className="mt-4 text-center">
            <span className="text-gray-600">Don't have an account?</span>
            <Link
              to="/register"
              className="ml-2 text-blue-500 font-semibold hover:underline"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
