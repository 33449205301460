import ApiInterceptor from "../../utils/ApiInterceptor";

const getAllMarketPlaceData = (setData, influencerId = "NA") => {
  ApiInterceptor.get("/influencer/marketPlace")
    .then(({ data }) => {
      if (influencerId === "NA") {
        setData(data["influencers"]);
      } else {
        const index = data["influencers"].findIndex(
          (obj) => obj.id === influencerId
        );
        setData(data["influencers"][index]);
      }
    })
    .catch((error) => {
      console.error("Error fetching marketplace data:", error);
      // Optionally handle errors, for example: set an error state
    });
};

export { getAllMarketPlaceData };

