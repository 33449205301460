import axios from "axios";
import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { loginError, loginStart, loginSuccess } from "../redux/Slice/userSlice";
import TextField from "@mui/material/TextField";
import Button from "@mui/joy/Button";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../Pages/OverlayDisableAccountPage";
import ApiInterceptor from "../utils/ApiInterceptor";

function Setting() {
  const { currentUser } = useSelector((state) => state.user);
  const current = currentUser?.others ? currentUser?.others : currentUser;

  const [selectedImg, setSelectedImg] = useState(""); 
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [campany, setCampany] = useState("");
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [inviteName, setInviteName] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [userData, setUserData] = useState(null);
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [fileInputState, setFileInputState] = useState(""); //Image
  const [invites, setInvites] = useState([]);
  const [showInvites, setShowInvites] = useState(false);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  };
  const currentUserId = Cookies.get("userid");
  // console.log(currentUserId);
  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);
  const checkIfEmailExistsInCompanyDatabase = async (email) => {
    const res = await ApiInterceptor.get("users", config);
    const companyEmails = res.data.map((user) => user.email);
    return companyEmails.includes(email);
  };
  const fetchUserData = async () => {
    try {
      // console.log(currentUserId);
      const { data } = await ApiInterceptor.get(
        `currentUser?userid=${currentUserId}`,
        config
      );
      // console.log(data);
      const updatedInvitesPromises = data.invitedUsers.map(async (invite) => {
        const isEmailInCompanyDatabase =
          await checkIfEmailExistsInCompanyDatabase(invite.email);
        // invite.status = isEmailInCompanyDatabase ? "Accepted" : "Pending";
        invite.status = isEmailInCompanyDatabase && "Pending";
        return invite;
      });

      // Use Promise.all to await all promises
      const updatedInvites = await Promise.all(updatedInvitesPromises);

      setName(data.name || ""); // Replace with the correct data field names
      setInvites(updatedInvites);
      setUserName(data.userName || "");
      setEmail(data.email || "");
      setPhone(data.phone || "");
      setCampany(data.company || "");
      setUrl(data.url || "");
      setUsername(data.username || "");
      setPassword(data.password || "");
      setProfile(data.profile || "");
      setImage(data.image || "");
      setUserData(data); // Store user data in the state
      setLoading(false);
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserData();
    // Fetch user data when the component mounts
  }, []);

  // console.log(invites);
  useEffect(() => {
    handleImage();
  }, [selectedImg]);

  // to prev the image file
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedImg(file);
    setFileInputState(e.target.value);
  };

  // to read the prev the image file
  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // setPreviewSource(reader.result);
    };
  };

  // upload image url through reader
  const handleImage = () => {
    if (!selectedImg) {
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg);
    reader.onloadend = () => {
      uploadImage(selectedImg).finally(() => setLoading(false));
    };
    reader.onerror = () => {
      // console.error("AHHHHHHHH!!");
    };
  };

  // upload image api
  const uploadImage = async (file) => {
    try {
      const maxSizeMB = 10;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;

      if (file.size > maxSizeBytes) {
        toast.error(`Image size should be less than ${maxSizeMB} MB`);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      const { data } = await ApiInterceptor.post("upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      setFileInputState("");
      setPreviewImage(data.publicUrl);
      setImage(data.publicUrl);
      toast.success("Image uploaded");
    } catch (err) {
      // console.error(err);
      toast.error(err?.response?.data?.message || "Error uploading image");
    }
  };

  const userid = Cookies.get("userid");

  // to subit the edit form
  const submit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      // console.log(userid);
      const { data } = await ApiInterceptor.put(
        `user/update`,
        {
          name: name,
          email: email,
          // password: password,
          username: username,
          phone: phone,
          url: url,
          campany: campany,
          profile: image,
          image,
          userid,
        },
        config
      );
      // console.log(data);
      dispatch(loginSuccess(data));
      toast.success("Updated successfully");
      setName(data.name || ""); // Replace with the correct data field names
      setUserName(data.userName || "");
      setEmail(data.email || "");
      setPhone(data.phone || "");
      setCampany(data.company || "");
      setUrl(data.url || "");
      setUsername(data.username || "");
      // setPassword(data.password || "");
      setProfile(data.profile || "");
      // window.location.reload();
      setIsEditMode(false);
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message || "Error updating profile");
    }
  };

  const invite = async (e) => {
    e.preventDefault();
    try {
      if (!inviteName || !inviteEmail) {
        toast.error("Please fill all the fields", {
          duration: 1200,
        });
        return;
      }
      const response = await ApiInterceptor.post("inviteuser", {
        name: inviteName,
        email: inviteEmail,
        companyId: currentUserId,
      });
      if (response?.status === 200) {
        toast.success("Invitation Sent Successfully");
        setInviteName("");
        setInviteEmail("");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Invitation Already Sent");
      setInviteName("");
      setInviteEmail("");
    }
  };

  const deleteinvitedUser = async (id) => {
    try {
      const response = await ApiInterceptor.delete(
        `deleteInvitendUser?userId=${currentUserId}&invitedUserId=${id}`,
        config
      );
      // console.log(response);
      if (response.data.success === true) {
        toast.success("User Deleted Successfully");
        window.location.reload();
      } else {
        toast.error("User Not Found");
      }
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message || "User Not Found");
    }
  };

  const statusColors = {
    "In Review": "text-blue-500",
    approved: "text-green-500",
    disapproved: "text-red-500",
    pending: "text-blue-900",
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (previewImage) setPreviewImage(null);
  };

  const Toggler = () => {
    setShowInvites(!showInvites);
    // console.log(showInvites);
  };
  const [openDisbalePageModal, setOpenDisablePageModal] = useState(false);

  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);

  if (userAccountStatus) return <OverlayDisableAccountPage isOpen={true} />;

  return (
    <div>
      <div className="p-[20px] md:p-2  h-auto flex flex-col items-center overflow-y-auto ">
        <form onSubmit={invite} className="flex flex-col items-center">
          <div className="flex flex-row space-x-2 items-center justify-center">
            <TextField
              className="border-0 bg-white text-[#b2aeae] focus:outline-none"
              id="name"
              label="Name"
              // variant="outlined"
              type="text"
              value={inviteName}
              onChange={(e) => setInviteName(e.target.value)}
            />
            <TextField
              id="email"
              label="Email"
              // variant="outlined"
              className="bg-white  text-[#b2aeae]  focus:outline-none"
              type="email"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
            />
            <input
              className="text-center cursor-pointer py-[14px] text-white w-[26%] my-[10px] rounded-lg bg-blue-500"
              type="submit"
              value={"Send Request"}
            />
          </div>
        </form>
        <div className="flex mt-[10px] mb-[5px] justify-between">
          <button onClick={Toggler}>
            <h1 className="text-center cursor-pointer py-3 text-white min-w-[290px] max-w-[300px]  rounded-lg bg-blue-500">
              {showInvites
                ? " Hide Invitation Status"
                : " Show Invitation Status"}
            </h1>
          </button>
        </div>
        {/* Invitation Status table here  */}
        {showInvites && (
          <div className="rounded-lg bg-white relative group py-[10px] w-[55%] p-3 md:w-[55%] ">
            <table className="table-fixed  md:gap-2 text-center lg:w-[100%]">
              <thead>
                <tr className="">
                  <th className="text-base pr-[10px] font-semibold md:text-[14px] 8k:text-2xl mobile:px-[20px] 3xl:text-[1vw]">
                    Name
                  </th>
                  <th className="text-base w-[200px] pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                    Email
                  </th>
                  <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                    Status
                  </th>
                  <th className="text-base w-[20%] pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="my-[20px] mx-[20px] space-y-[10px]">
                {invites.map((i) => (
                  <tr key={i._id} className="">
                    <td className=" capitalize text-xs pt-[20px] md:text-[14px] font-semibold">
                      {i.name}
                    </td>
                    <td className=" text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                      {i.email}
                    </td>
                    <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] ">
                      <span
                        className={`text-sm capitalize ${
                          statusColors[i.status]
                        } font-semibold`}
                      >
                        {i.status}
                      </span>
                    </td>
                    <td className=" text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] ">
                      <Button
                        onClick={() => deleteinvitedUser(i._id)}
                        color="danger"
                        variant="solid"
                      >
                        Delete
                        {/* <DeleteIcon /> */}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="md:w-[500px] md:p-[28px]">
          <div className="relative flex justify-center group">
            {!loading ? (
              <>
                {image || previewImage ? (
                  <img
                    className="w-[120px] h-[120px] rounded-full object-cover"
                    src={previewImage || image}
                    alt="profile"
                  />
                ) : (
                  // <img
                  //   className="w-[120px] h-[120px] rounded-full object-cover border-2 border-gray-600 shadow-md"
                  //   src="/Images/profileimage.jpg"
                  //   alt="profile"
                  // />
                  <div className="relative">
                    <img
                      className="w-[120px] h-[120px] rounded-full object-cover border-2 border-gray-600 shadow-md"
                      src="/Images/profileimage.jpg"
                      alt="profile"
                    />
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-green-500 bg-opacity-75 text-white rounded-full">
                      <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75"></div>
                      <span className="relative text-[14px] ml-2 text-ellipsis">
                        Please upload an image
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <SpinnerCircular
                size="90"
                className="w-full flex items-center mx-auto"
                thickness="100"
                speed="600"
                color="white"
                secondaryColor="black"
              />
            )}

            {isEditMode && (
              <img
                className="w-24 h-24 absolute -bottom-12 md:left-[214px] left-[174px] cursor-pointer sm:left-[50%]"
                src="/Images/galleryedit.png"
                alt="tag"
                onClick={() => document.getElementById("edit")?.click()}
              />
            )}

            <input
              id="edit"
              value={fileInputState}
              onChange={handleFileInputChange}
              hidden
              type="file"
              disabled={!isEditMode}
            />
          </div>
          <div className="flex my-[20px] items-center justify-center">
            <button
              className={`flex items-center justify-center cursor-pointer py-2 px-4 mt-5 text-white bg-blue-500 rounded-lg ${
                isEditMode ? "bg-opacity-70" : ""
              }`}
              onClick={toggleEditMode}
            >
              <h1
                className="text-center cursor-pointer  text-white
                rounded-lg"
              >
                Edit Profile
              </h1>
              <img
                className={`w-6 h-6 ml-2 transform invert-colors ${
                  isEditMode ? "rotate-45" : ""
                }`}
                src="/Images/useredit.png"
                alt="edit"
              />
            </button>
          </div>
          {/* //previous version  */}
          {/* <form onSubmit={submit}>
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!isEditMode}
            />
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="text"
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={!isEditMode}
            />
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="text"
              placeholder="Campany Name"
              value={campany}
              onChange={(e) => setCampany(e.target.value)}
              disabled={!isEditMode}
            />
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="text"
              placeholder="Updated Campany website URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              disabled={!isEditMode}
            />
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="number"
              placeholder="+91"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={!isEditMode}
            />
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="email"
              placeholder="Communication Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!isEditMode}
            />
            <input
              className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
              type="text"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={!isEditMode}
            />

            <input
              className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-blue-500"
              type="submit"
            />
          </form> */}
          {/* //changed version // */}
          <form
            onSubmit={submit}
            className="max-w-lg mx-auto p-8 bg-gray-100 shadow-lg rounded-lg"
          >
            <div className="mb-4">
              <label className=" text-gray-500">Business Name</label>
              <input
                className="bg-white pl-6 text-gray-600 my-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg shadow-sm"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!isEditMode}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-500">Username</label>
              <input
                className="bg-white pl-6 text-gray-600 my-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg shadow-sm"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={!isEditMode}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-500">Company Name</label>
              <input
                className="bg-white pl-6 text-gray-600 my-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg shadow-sm"
                type="text"
                placeholder="Company Name"
                value={campany}
                onChange={(e) => setCampany(e.target.value)}
                disabled={!isEditMode}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-500">Company Website Url</label>
              <input
                className="bg-white pl-6 text-gray-600 my-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg shadow-sm"
                type="text"
                placeholder="Updated Company website URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={!isEditMode}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-500">Phone</label>
              <input
                className="bg-white pl-6 text-gray-600 my-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg shadow-sm"
                type="number"
                placeholder="+91"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                disabled={!isEditMode}
              />
            </div>
            <div className="mb-4">
              <label className=" text-gray-500">Email</label>
              <input
                className="bg-white pl-6 text-gray-600 my-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg shadow-sm"
                type="email"
                placeholder="Communication Mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!isEditMode}
              />
            </div>

            <div>
              <input
                className={`text-center cursor-pointer py-2 text-white w-full my-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors duration-200 ${
                  !isEditMode ? " hidden" : ""
                }`}
                type="submit"
                value="Submit"
                disabled={!isEditMode}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Setting;
