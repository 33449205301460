import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ApiInterceptor from "../../utils/ApiInterceptor";
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    data: [],
    graphDataDB: [],
    error: null,
  },
  reducers: {
    DashBoardData: (state, action) => {
      state.data = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearData: (state) => {
      state.data = null;
    },
    clearGraphData: (state) => {
      state.graphDataDB = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashBoardData.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(fetchDashBoardData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchDashBoardData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // fetch graph data
    builder.addCase(fetchGraphData.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(fetchGraphData.fulfilled, (state, action) => {
      state.graphDataDB = action.payload;
    });
    builder.addCase(fetchGraphData.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const fetchDashBoardData = createAsyncThunk(
  "dashboard/getDashBoardData",
  async ({ company_id }, { rejectWithValue }) => {
    try {
      // const response = await fetch(
      //   `https://test.cloutin.co/api/companyDashboard/getDashBoardData/?company_id=${company_id}`
      // );
      // const data = await response.json();
      const res = await ApiInterceptor.get(
        `companyDashboard/getDashBoardData/?company_id=${company_id}`
      );
      console.log(res?.data?.data, "res of dashboard");
      if (res?.data) {
        return res?.data?.data;
      } else {
        return rejectWithValue("No data found");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchGraphData = createAsyncThunk(
  "dashboard/getGraphData",
  async ({ company_id }, { rejectWithValue }) => {
    try {
      const response = await ApiInterceptor.get(
        `companyDashboard/getGraphData/?company_id=${company_id}`
      );
      if (response) {
        return response?.data?.data;
      } else {
        return rejectWithValue("No data found");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const { DashBoardData, clearError, clearData, clearGraphData } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
