import React, { useEffect, useContext, useState } from "react";
import CreateCampaign from "../Component/CreateCampaign";
import axios from "axios";
import Integration from "../Component/Integration";
import Campaign2 from "../Component/Campaign/Campaign2";
import Affiliate2 from "../Component/Affiliate/Affiliate2";
import Campaign from "../Component/Campaign/Campaign";
import Main from "../Component/Main";
import Marketplace from "../Component/MarketPlace/Marketplace";
import Report from "../Component/Report";
import InfluencerInfo from "../Component/MarketPlace/InfluencerInfo";
import Wallet from "../Component/Wallet";

import Setting from "../Component/Setting";
import Formaffiliate from "../Component/Affiliate/Formaffiliate";
import toast from "react-hot-toast";
import { logout } from "../redux/Slice/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SinglePage from "./SinglePage";
import HiredInfluencer from "../Component/HiredInfluencer";
import { SharedStateProvider } from "../context/SharedStateContext";
// import SingleCampaign from "../Component/SingleCampaign";
import Affiliate from "../Component/Affiliate/Affiliate";
import Affiliatehome from "../Component/Affiliate/Affilicatehome";
import Cookies from "js-cookie";
import { SharedStateContext } from "../context/SharedStateContext";
import { loginError, loginStart, loginSuccess } from "../redux/Slice/userSlice";
import SimplePopup from "../Component/Popup";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import Conversion from "../Component/Conversion";
import { Avatar } from "@mui/material/node";
import { IoAddCircleOutline } from "react-icons/io5";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../redux/Slice/UserAccounStatusSlice";
import { clearData, clearGraphData } from "../redux/Slice/dashboardSlice";
import "./home.css";
import ErrorPage from "../utils/ErrorPage";
import ApiInterceptor from "../utils/ApiInterceptor";

// import Affiliatehome from "../Component/Affilicatehome";
function Home() {
  const {
    create,
    setCreate,
    marketplace,
    setMarketplace,
    hiredInfluencer,
    setHiredInfluencer,

    dashBoard,
    setDashBoard,
    visible,
    setVisible,
    integration,
    setIntegration,
    report,
    setReport,
    campaigns,
    setCampaigns,
    wallet,
    setWallet,
    setting,
    setSetting,
    affiliate,
    setAffiliate,
    conversion,
    setConversion,
  } = useContext(SharedStateContext);
  const navigate = useNavigate();
  const { id1, id2, id3, id4, id5, id6 } = useParams();
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUserId = Cookies.get("userid");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  };
  const [errors, setErrors] = useState(false);
  const dispatch = useDispatch();
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletDataError, setWalletDataError] = useState("");
  const [walletBalanceLoading, setWalletBalanceLoading] = useState(false);

  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);

  const fetchWalletBalance = async () => {
    const companyId = Cookies.get("userid");

    try {
      setWalletBalanceLoading(true);
      const walletResponse = await ApiInterceptor.get(
        `businesswallet/getBusinessWalletByBusinessId/${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
      localStorage.setItem(
        "walletBalance",
        walletResponse?.data?.currentBalance
      );
      setWalletBalance(walletResponse?.data?.currentBalance);
    } catch (error) {
      setErrors(true);
      setWalletDataError(
        error?.response?.data?.message || "Something went wrong"
      );
      setWalletBalance(0);
      setWalletBalanceLoading(false);
    } finally {
      setWalletBalanceLoading(false);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const fetchUserData = async () => {
    try {
      if (currentUserId) {
        const { data } = await ApiInterceptor.get(
          `currentUser?userid=${currentUserId}`,
          config
        );

        setUsername(data.username || "");
        setImage(data?.image || "");
        setLoading(false);
      } else {
        navigate("/login");
      }
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message);
      setErrors(true);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const logOut = () => {
    dispatch(logout());
    dispatch(clearGraphData());
    dispatch(clearData());
    Cookies.remove("email");
    Cookies.remove("username");
    Cookies.remove("image");
    Cookies.remove("token");
    localStorage.clear();
    navigate("/login");
    toast.success("Logout successfully ");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleMarketPlace = () => {
    setMarketplace(true);
    setDashBoard(false);
    setCreate(false);
    setReport(false);
    setWallet(false);
    setHiredInfluencer(false);
    setIntegration(false);
    setCampaigns(false);
    setSetting(false);
    setVisible(false);
    setAffiliate(false);
    setConversion(false);
    navigate("/");
  };

  if (errors) return <ErrorPage />;

  return (
    <>
      <div className="flex font-Poppins ">
        <div className="lg:w-[20%] hidden lg:flex flex-col border-r border-blue-200 bg-white justify-between p-[20px] 8k:h-screen ">
          <div className="start ">
            <div className="flex items-center">
              <img
                className="w-9 h-9 8k:w-[5vh] 8k:h-[5vh] 3xl:w-[2.5vw] 3xl:h-[2.5vw]"
                src="/Images/logo.png"
                alt="logo"
              />
              <h1 className="ml-[20px] font-bold 8k:text-3xl 3xl:text-[1.2vw] uppercase">
                CLOUTIN
              </h1>
            </div>
            <div className="space-y-[20px] mt-[40px] 8k:space-y-[40px] 8k:mt-[80px] 3xl:mt-[2vw]">
              <div
                className="flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-blue-500"
                onClick={() => {
                  setCreate(true);
                  setDashBoard(false);
                  setMarketplace(false);
                  setReport(false);
                  setWallet(false);
                  setHiredInfluencer(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setVisible(false);
                  setAffiliate(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <IoAddCircleOutline size={30} className="text-white" />
                <h1 className="ml-[20px] text-white">Create Campaign</h1>
              </div>
              {/* dash  */}
              <div
                className={
                  dashBoard
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                }
                onClick={() => {
                  setDashBoard(true);
                  setMarketplace(false);
                  setAffiliate(false);
                  setCreate(false);
                  setHiredInfluencer(false);
                  setReport(false);
                  setWallet(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw] "
                  src={
                    dashBoard
                      ? "/Images/dashboard.png"
                      : "/Images/dashboard2.png"
                  }
                  alt="logo"
                />
                <h1
                  className={
                    dashBoard
                      ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw] 3xl:text-[1vw]"
                      : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw] 3xl:text-[1vw]"
                  }
                >
                  Dashboard
                </h1>
              </div>
              {/* market  */}

              <div
                className={
                  marketplace
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                }
                onClick={() => {
                  setMarketplace(true);
                  setDashBoard(false);
                  setAffiliate(false);
                  setCreate(false);
                  setReport(false);
                  setWallet(false);
                  setHiredInfluencer(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={
                    marketplace
                      ? "/Images/marketplace.png"
                      : "/Images/marketplace(2).png"
                  }
                  alt="logo"
                />
                <h1
                  className={
                    !marketplace
                      ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Marketplace
                </h1>
              </div>
              {/* Hired Influencer */}
              <div
                className={
                  hiredInfluencer
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                }
                onClick={() => {
                  setHiredInfluencer(true);
                  setMarketplace(false);
                  setDashBoard(false);
                  setAffiliate(false);
                  setCreate(false);
                  setReport(false);
                  setWallet(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={
                    hiredInfluencer
                      ? "/Images/marketplace.png"
                      : "/Images/marketplace(2).png"
                  }
                  alt="logo"
                />
                <h1
                  className={
                    !hiredInfluencer
                      ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Hired Influencer
                </h1>
              </div>
              {/* campaign  */}
              <div
                className={
                  !campaigns
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:text-3xl 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:text-3xl 3xl:h-[2.5vw]"
                }
                onClick={() => {
                  setCampaigns(true);
                  setMarketplace(false);
                  setDashBoard(false);
                  setAffiliate(false);
                  setCreate(false);
                  setWallet(false);
                  setReport(false);
                  setHiredInfluencer(false);
                  setIntegration(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={!campaigns ? "/Images/campaign.png" : "Images/camp.png"}
                  alt="logo"
                />
                <h1
                  className={
                    !campaigns
                      ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Campaigns
                </h1>
              </div>
              {/* wallet  */}
              <div
                className={
                  !wallet
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                }
                onClick={() => {
                  setMarketplace(false);
                  setDashBoard(false);
                  setAffiliate(false);
                  setCreate(false);
                  setWallet(true);
                  setReport(false);
                  setHiredInfluencer(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={wallet ? "/Images/wallet(2).png" : "/Images/wallet.png"}
                  alt="logo"
                />
                <h1
                  className={
                    !wallet
                      ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Wallet
                </h1>
              </div>
              {/* report  */}
              {/* <div
                className={
                  !report
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p- 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                }
                onClick={() => {
                  setMarketplace(false);
                  setDashBoard(false);
                  setAffiliate(false);
                  setHiredInfluencer(false);
                  setCreate(false);
                  setWallet(false);
                  setReport(true);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={report ? "/Images/report(1).png" : "/Images/report.png"}
                  alt="logo"
                />
                <h1
                  className={
                    !report
                      ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Report
                </h1>
              </div> */}
              {/* affiliate  */}
              <div
                className={
                  !affiliate
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p- 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3 3xl:h-[2.5vw]"
                }
                onClick={() => {
                  setAffiliate(true);
                  setMarketplace(false);
                  setHiredInfluencer(false);
                  setDashBoard(false);
                  setCreate(false);
                  setWallet(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={"/Images/affiliate.png"}
                  alt="logo"
                />
                <h1
                  className={
                    !affiliate
                      ? " xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "xl:text-[14px] ml-[22px] w-[80%] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Affiliate
                </h1>
              </div>
              {/* integration  */}
              <div
                className={
                  !integration
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:text-3xl 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:text-3xl 8k:p-3 3xl:h-[2.5vw]"
                }
                onClick={() => {
                  setMarketplace(false);
                  setDashBoard(false);
                  setAffiliate(false);
                  setHiredInfluencer(false);
                  setCreate(false);
                  setWallet(false);
                  setReport(false);
                  setIntegration(true);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(false);
                  navigate("/");
                }}
              >
                <img
                  className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                  src={
                    integration
                      ? "/Images/integrate(2).png"
                      : "/Images/integration.png"
                  }
                  alt="logo"
                />
                <h1
                  className={
                    !integration
                      ? "ml-[20px] xl:text-[14px] text-xs w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "ml-[20px] xl:text-[14px] text-xs w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Integration
                </h1>
              </div>
              {/* Conversion  */}
              <div
                className={
                  !conversion
                    ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:text-3xl 8k:p-3 3xl:h-[2.5vw] transition duration-300 ease-in-out hover:bg-sky-100"
                    : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:text-3xl 8k:p-3 3xl:h-[2.5vw]"
                }
                onClick={() => {
                  setMarketplace(false);
                  setDashBoard(false);
                  setAffiliate(false);
                  setHiredInfluencer(false);
                  setCreate(false);
                  setWallet(false);
                  setReport(false);
                  setIntegration(false);
                  setCampaigns(false);
                  setSetting(false);
                  setConversion(true);
                  navigate("/");
                }}
              >
                {/* <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={
                  integration
                    ? "/Images/integrate(2).png"
                    : "/Images/integration.png"
                }
                alt="logo"
              /> */}
                <SwapHorizontalCircleOutlinedIcon
                  style={{ color: "#b3aeaf" }}
                />
                <h1
                  className={
                    !conversion
                      ? "ml-[20px] xl:text-[14px] text-xs w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                      : "ml-[20px] xl:text-[14px] text-xs w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                  }
                >
                  Conversion
                </h1>
              </div>
            </div>
          </div>

          <div className=" end border border-x-0 border-b-0 pt-[100px]  ">
            {/* end above  */}
            <div
              className={
                !setting
                  ? "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-3 transition duration-300 ease-in-out hover:bg-sky-200"
                  : "flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-3"
              }
              onClick={() => {
                setMarketplace(false);
                setDashBoard(false);
                setCreate(false);
                setHiredInfluencer(false);
                setAffiliate(false);
                setWallet(false);
                setReport(false);
                setIntegration(false);
                setCampaigns(false);
                setConversion(false);
                setSetting(true);
                navigate("/");
              }}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src={!setting ? "/Images/setting.png" : "/Images/setting2.png"}
                alt="logo"
              />
              <h1
                className={
                  !setting
                    ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                    : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500 8k:text-3xl 8k:p-3 3xl:text-[1vw]"
                }
              >
                Settings
              </h1>
            </div>
            <div
              className="flex cursor-pointer justify-center items-center rounded-lg p-2 mx-4 8k:p-3 transition duration-300 ease-in-out hover:bg-sky-200"
              onClick={logOut}
            >
              <img
                className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                src="/Images/logout.png"
                alt="logo"
              />
              <h1 className="ml-[20px] xl:text-[14px] w-[45%] text-xs xl:w-[80%] text-[#b2aeae] 8k:text-3xl 8k:p-3 3xl:text-[1vw]">
                Logout
              </h1>
            </div>
          </div>
        </div>

        <div className="bg-[#f8f8f8]  lg:w-[80%] ">
          <div>
            <div className="flex md:pl-[40px] justify-between shadow-sm bg-white h-[60px]  items-center  8k:h-[7vh] 3xl:h-[4vw]">
              <img
                className="w-5 h-5 mx-[10px] flex lg:hidden cursor-pointer"
                src="/Images/hamburger.png"
                alt="ham"
                onClick={() => setVisible(true)}
              />

              <div className=" rounded-lg flex items-center w-[60%] 8k:h-[5vh] ">
                {/* <img
                className="ml-[20px] mr-[10px] h-7 w-7 8k:w-[2vh] 8k:h-[2vh] mobile:w-5 mobile:h-5 3xl:h-[2vw] 3xl:w-[2vw]"
                src="/Images/search.png"
                alt=""
              /> */}
                {/* <input
                className="p-2 w-full rounded-lg focus:outline-none  8k:text-3xl mobile:text-xs 3xl:h-[3vw] 3xl:w-[100%] 3xl:text-[1.2vw]"
                type="text"
                placeholder="Search you Influencers"
              /> */}
              </div>

              {/* search section  */}
              <div className="flex items-center space-x-5 mr-[30px] mobile:justify-around xsm:space-x-1">
                {/* <img
                  className="w-10 h-10  8k:h-[5vh] 8k:w-[5vh] mobile:w-9 3xl:h-[3vw] 3xl:w-[3vw] xsm:ml-[5vw] "
                  src="/Images/notification.png"
                  alt="notification"
                /> */}
                <SimplePopup userId={currentUserId} />

                <div
                  className="bg-blue-500 cursor-pointer rounded-3xl flex items-center justify-between text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-900"
                  onClick={() => {
                    setMarketplace(false);
                    setDashBoard(false);
                    setAffiliate(false);
                    setCreate(false);
                    setHiredInfluencer(false);
                    setWallet(true);
                    setReport(false);
                    setIntegration(false);
                    setCampaigns(false);
                    setSetting(false);
                    navigate("/");
                  }}
                >
                  {/* <h1 className="text-ellipsis">&#8377;{walletBalance}</h1> */}
                  <h1 className="text-[20px] sm:text-[20px] max-[1800]:text-3xl  truncate">
                    {walletBalanceLoading ? (
                      "Calculating..."
                    ) : (
                      <>
                        &#8377; {Number(walletBalance).toLocaleString("en-IN")}
                      </>
                    )}
                  </h1>
                </div>

                {/* profile  */}
                <div className="relative">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    {image ? (
                      <img
                        className="w-9 h-9 rounded-full mr-[5px] 8k:h-[5vh] 8k:w-[5vh] 3xl:h-[3vw] 3xl:w-[3vw]"
                        src={image}
                        alt="profile"
                      />
                    ) : (
                      <Avatar className="mr-1" />
                    )}
                    <h1 className="text-xs md:text-lg mr-[20px] capitalize">
                      {username}
                    </h1>
                  </div>
                  {isOpen && (
                    <div className="absolute top-full left-0 mt-2 w-full bg-white shadow-lg border rounded-md">
                      <div className=" flex justify-center items-center">
                        <img
                          className="w-6 h-6 8k:w-9 8k:h-9 3xl:h-[1.5vw] 3xl:w-[1.5vw]"
                          src="/Images/logout.png"
                          alt="logo"
                        />
                        <button
                          onClick={logOut}
                          className="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!id1 && !id2 && !id3 && !id4 && !id5 && !id6 && (
              <div>
                {dashBoard && (
                  <div className="py-[20px] px-[20px] 2xl:px-[20px] 2xl:py-[0px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] lg:px-[10px]  mobile:h-[140vh] main-component">
                    <Main handleMarketPlace={handleMarketPlace} />
                  </div>
                )}
                {affiliate && (
                  <div className="py-[20px] 2xl:px-[120px] 8k:px-[200px] 3xl:px-[180px] rk:px-[120px] 1440k:px-[180px] lgk:px-[100px]  md:px-[100px]  sm:px-[35px]  xsm:px-[10px] mobile:px-[10px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%]  px-[20px] lg:px-[10px]  mobile:h-[140vh]">
                    <Affiliate />
                  </div>
                )}
                {marketplace && (
                  <div className="py-[20px] 2xl:px-[140px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[90px]">
                    {/* <div className="py-[20px] 2xl:px-[140px] overflow-y-scroll w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[90px]">  */}
                    <SharedStateProvider>
                      <Marketplace />
                    </SharedStateProvider>
                  </div>
                )}
                {create && (
                  <div className=" py-[10px] w-screen flex justify-center lg:w-[100%] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[28vw] 4k:px-[25vw] rk:px-[25vw] 1440k:px-[23vw] lgk:px-[19vw]">
                    <SharedStateProvider>
                      <CreateCampaign />
                    </SharedStateProvider>
                  </div>
                )}

                {report && (
                  <div className="py-[20px] h-[calc(100vh-60px)] w-screen flex justify-center lg:w-[100%] md:px-[20px] lg:px-[40px] 8k:h-[90vh] items-center">
                    <Report />
                  </div>
                )}
                {integration && (
                  <div className="py-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px]   8k:h-[90vh] ">
                    <Integration />
                  </div>
                )}
                {hiredInfluencer && (
                  <div className=" overflow-y-scroll  py-[20px] h-[calc(100vh-60px)] w-screen flex justify-center lg:w-[100%] md:px-[20px] lg:px-[40px] hired-influencers max-w-7xl mx-auto">
                    <SharedStateProvider>
                      <HiredInfluencer />
                    </SharedStateProvider>
                  </div>
                )}
                {wallet && (
                  <div className="lg:py-[20px] lg:w-full overflow-y-scroll w-screen flex justify-center md:px-[20px] lg:px-[40px] 8k:px-[0px] 2xl:w-[80vw]">
                    <Wallet />
                  </div>
                )}
                {conversion && (
                  <div className="lg:py-[20px] lg:w-full overflow-y-scroll w-screen flex justify-center md:px-[20px] lg:px-[40px] 8k:px-[0px] 2xl:w-[80vw]">
                    <Conversion />
                  </div>
                )}
                {campaigns && (
                  <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center campaign-component">
                    <Campaign />
                  </div>
                )}
                {setting && (
                  <div className=" overflow-y-scroll  py-[20px] h-[calc(100vh-60px)] w-screen flex justify-center lg:w-[100%] md:px-[20px] lg:px-[40px]">
                    <Setting />
                  </div>
                )}
              </div>
            )}

            {id1 && (
              <div className="py-[20px] 2xl:px-[100px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 8k:px-[50px] 4k:px-[50px] ">
                <SinglePage />
              </div>
            )}

            {id2 && (
              <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px]">
                <Campaign2 />
              </div>
            )}
            {id3 && (
              <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[23vw] 4k:px-[20vw] rk:px-[20vw] 1440k:px-[15vw] lgk:px-[11vw] ">
                <Formaffiliate />
              </div>
            )}
            {id4 && (
              <div className="py-[2px] 2xl:px-[30px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] lg:px-[40px] 1440k:px-[20px] 4k:items-center campaign-component">
                <Affiliatehome />
              </div>
            )}
            {id5 && (
              <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[23vw] 4k:px-[20vw] rk:px-[20vw] 1440k:px-[15vw] lgk:px-[11vw] ">
                <Affiliate2 />
              </div>
            )}
            {id6 && (
              <div className="py-[20px] 2xl:px-[20px] h-[calc(100vh-60px)] overflow-y-scroll md:h-[calc(100vh-60px)] lg:h-[calc(100vh-62px)] w-screen lg:w-[100%] px-[20px] md:px-[20px] lg:px-[180px] 8k:px-[30vw] 3xl:px-[23vw] 4k:px-[20vw] rk:px-[20vw] 1440k:px-[15vw] lgk:px-[11vw] ">
                <InfluencerInfo />
              </div>
            )}

            {/* id === "SingleCampaign" */}
          </div>
        </div>

        {/* 
          Responsive navbar 
    */}

        {visible && (
          <div className="overflow-y-scroll left-0 top-0 fixed z-50 w-full h-screen bg-black/70 mobile:h-[150vh] ">
            <div className="flex flex-col p-2 top-0 left-0 w-[75%] h-auto md:w-[45%] bg-white  text-black mobile:w-[60]">
              <div className="flex mt-4 w-full items-center">
                <div className="flex ml-[30px] justify-between w-full items-center">
                  <div className="flex items-center">
                    <img
                      className="w-9 h-9"
                      src="/Images/logo.png"
                      alt="logo"
                    />
                    <h1 className="ml-[20px] text-black font-bold">CLOUTIN</h1>
                  </div>
                  {/* close button  */}
                  <div
                    onClick={() => setVisible(!visible)}
                    className="flex cursor-pointer text-black/60 justify-center lg:hidden"
                  >
                    {visible && (
                      <img
                        className="w-5 h-5 mr-[10px] cursor-pointer"
                        src="/Images/cross.png"
                        alt="arr"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between h-full items-center mt-12 mobile:w-[40vh] mobile:mt-1">
                <div className="flex flex-col justify-between mt-2 h-full space-y-[50px]  items-center">
                  <div className="start flex flex-col justify-between h-full ">
                    <div className="space-y-[20px] mt-[40px]">
                      {/* create section  */}
                      <div
                        className="flex justify-center cursor-pointer items-center rounded-lg p-2 mx-4 bg-blue-500"
                        onClick={() => {
                          setCreate(true);
                          setDashBoard(false);
                          setMarketplace(false);
                          setReport(false);
                          setWallet(false);
                          setHiredInfluencer(false);
                          setIntegration(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          setAffiliate(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <IoAddCircleOutline size={30} className="text-white" />
                        <h1 className="ml-[20px] text-white">
                          Create Campaign
                        </h1>
                      </div>
                      <div
                        className={
                          dashBoard
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF] 8k:p-5"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 8k:p-5"
                        }
                        onClick={() => {
                          setDashBoard(true);
                          setMarketplace(false);
                          setCreate(false);
                          setReport(false);
                          setWallet(false);
                          setHiredInfluencer(false);
                          setIntegration(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          setAffiliate(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            dashBoard
                              ? "/Images/dashboard.png"
                              : "/Images/dashboard2.png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            dashBoard
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                          }
                        >
                          Dashboard
                        </h1>
                      </div>
                      <div
                        className={
                          marketplace
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                        }
                        onClick={handleMarketPlace}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            marketplace
                              ? "/Images/marketplace.png"
                              : "Images/marketplace(2).png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !marketplace
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Marketplace
                        </h1>
                      </div>
                      <div
                        className={
                          hiredInfluencer
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                        }
                        onClick={() => {
                          setHiredInfluencer(true);
                          setMarketplace(false);
                          setDashBoard(false);
                          setCreate(false);
                          setReport(false);
                          setWallet(false);
                          setIntegration(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          setAffiliate(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            hiredInfluencer
                              ? "/Images/marketplace.png"
                              : "Images/marketplace(2).png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !hiredInfluencer
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Hired Influencer
                        </h1>
                      </div>
                      <div
                        className={
                          !campaigns
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setCampaigns(true);
                          setMarketplace(false);
                          setDashBoard(false);
                          setHiredInfluencer(false);
                          setCreate(false);
                          setWallet(false);
                          setReport(false);
                          setIntegration(false);
                          setSetting(false);
                          setVisible(false);
                          setAffiliate(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            campaigns
                              ? "/Images/campaign.png"
                              : "Images/camp.png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !campaigns
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Campaigns
                        </h1>
                      </div>
                      <div
                        className={
                          !wallet
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setMarketplace(false);
                          setDashBoard(false);
                          setCreate(false);
                          setWallet(true);
                          setReport(false);
                          setAffiliate(false);
                          setIntegration(false);
                          setCampaigns(false);
                          setHiredInfluencer(false);
                          setSetting(false);
                          setVisible(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            wallet
                              ? "/Images/wallet(2).png"
                              : "/Images/wallet.png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !wallet
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Wallet
                        </h1>
                      </div>

                      {/* <div
                        className={
                          !report
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setMarketplace(false);
                          setDashBoard(false);
                          setAffiliate(false);
                          setCreate(false);
                          setWallet(false);
                          setHiredInfluencer(false);
                          setReport(true);
                          setIntegration(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            report
                              ? "/Images/report(1).png"
                              : "/Images/report.png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !report
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Report
                        </h1>
                      </div> */}

                      <div
                        className={
                          !affiliate
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setMarketplace(false);
                          setDashBoard(false);
                          setAffiliate(true);
                          setCreate(false);
                          setWallet(false);
                          setReport(false);
                          setHiredInfluencer(false);
                          setIntegration(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={"/Images/affiliate.png"}
                          alt="logo"
                        />
                        <h1
                          className={
                            !affiliate
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Affiliate
                        </h1>
                      </div>

                      <div
                        className={
                          !integration
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setIntegration(true);
                          setMarketplace(false);
                          setDashBoard(false);
                          setCreate(false);
                          setWallet(false);
                          setHiredInfluencer(false);
                          setAffiliate(false);
                          setReport(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          setConversion(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            integration
                              ? "/Images/integrate(2).png"
                              : "/Images/integration.png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !integration
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Integration
                        </h1>
                      </div>

                      <div
                        className={
                          !conversion
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setConversion(true);
                          setIntegration(false);
                          setMarketplace(false);
                          setDashBoard(false);
                          setCreate(false);
                          setWallet(false);
                          setHiredInfluencer(false);
                          setAffiliate(false);
                          setReport(false);
                          setCampaigns(false);
                          setSetting(false);
                          setVisible(false);
                          navigate("/");
                        }}
                      >
                        <SwapHorizontalCircleOutlinedIcon
                          style={{ color: "#b3aeaf" }}
                        />
                        <h1
                          className={
                            !conversion
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Conversion
                        </h1>
                      </div>

                      {/* transfer  */}
                      <div
                        className={
                          !setting
                            ? "flex cursor-pointer items-center rounded-lg p-2 mx-4"
                            : "flex cursor-pointer items-center rounded-lg p-2 mx-4 bg-[#E9F6FF]"
                        }
                        onClick={() => {
                          setMarketplace(false);
                          setDashBoard(false);
                          setCreate(false);
                          setWallet(false);
                          setReport(false);
                          setIntegration(false);
                          setHiredInfluencer(false);
                          setCampaigns(false);
                          setConversion(false);
                          setSetting(true);
                          setVisible(false);
                          setAffiliate(false);
                          navigate("/");
                        }}
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            !setting
                              ? "/Images/setting.png"
                              : "/Images/setting2.png"
                          }
                          alt="logo"
                        />
                        <h1
                          className={
                            !setting
                              ? "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-[#b2aeae]"
                              : "ml-[20px] xl:text-[14px] text-xs xl:w-[80%] text-blue-500"
                          }
                        >
                          Settings
                        </h1>
                      </div>
                      <div
                        className="flex cursor-pointer justify-center items-center rounded-lg p-2 mx-4"
                        onClick={logOut}
                      >
                        <img
                          className="w-6 h-6"
                          src="/Images/logout.png"
                          alt="logo"
                        />
                        <h1 className="ml-[20px] xl:text-[14px] text-xs w-[100%] text-[#b2aeae] ">
                          Logout
                        </h1>
                      </div>
                      {/* ends  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
