import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiInterceptor from "../utils/ApiInterceptor";

function EmailVerify() {
  const { id } = useParams(); // Extract the user ID from the URL
  const navigate = useNavigate();
  const [status, setStatus] = useState("verifying"); // 'verifying', 'success', 'error'
  const [error, setError] = useState("");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await ApiInterceptor.put(`user/verify-email/${id}`);
        setTimeout(() => {
          setStatus("success");
        }, 3000); // 3-second delay before showing success message
      } catch (err) {
        setStatus("error");
        setError(err.message || "Something went wrong");
      }
    };

    verifyEmail();
  }, [id]);

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {status === "verifying" && (
        <div className="flex flex-col items-center space-y-4 animate-pulse">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          <p className="text-lg text-blue-500">Verifying your email...</p>
        </div>
      )}
      {status === "success" && (
        <div className="flex flex-col items-center space-y-4 text-center">
          <div className="text-4xl text-green-500 font-bold">✓</div>
          <p className=" text-green-500 font-semibold text-xl">
            Your email has been verified successfully!
          </p>
          <button
            onClick={handleContinue}
            className="mt-4 px-6 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300"
          >
            Continue with Website
          </button>
        </div>
      )}
      {status === "error" && (
        <div className="flex flex-col items-center space-y-4 text-center">
          <div className="text-4xl text-red-500">✗</div>
          <p className="text-lg text-red-500">
            There was an error verifying your email.
          </p>
          <p className="text-sm text-gray-500">{error}</p>
        </div>
      )}
    </div>
  );
}

export default EmailVerify;
