import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Loader, { Loading } from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import ButtonCampaign from "../ButtonCampaign";
import { useDispatch, useSelector } from "react-redux";
import {
  initializeSocket,
  registerUser,
  subscribeToNotifications,
  disconnectSocket,
} from "../../socketManager";
import { showLoading, hideLoading } from "../../redux/Slice/loadingSlice";

import CampaignCard from "./CampaignCard";
import {
  getUserAccountStatus,
  setUserAccountStatus,
} from "../../redux/Slice/UserAccounStatusSlice";
import OverlayDisableAccountPage from "../../Pages/OverlayDisableAccountPage";
import { GiReceiveMoney } from "react-icons/gi";
import { TbDatabaseOff, TbHandClick } from "react-icons/tb";
import "./campaign.css";
import PaginationComponent from "../../utils/PaginationCompoent";
import CampaignsGraphReact from "./CampaignsGraphReact";
import { formateDate } from "../../utils/helperFunction";
import ErrorPage from "../../utils/ErrorPage";
import { IoMdRefresh } from "react-icons/io";
import ApiInterceptor from "../../utils/ApiInterceptor";
import { config } from "../../config";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Campaign() {
  const navigate = useNavigate();
  const [opt1, setOpt1] = useState(false);
  const [opt2, setOpt2] = useState(false);
  const [opt3, setOpt3] = useState(false);
  const [opt4, setOpt4] = useState(false);
  const [opt5, setOpt5] = useState(false);
  const [option, setOption] = useState(false);
  const [oopt1, setOopt1] = useState(false);
  const [oopt2, setOopt2] = useState(false);
  const [oopt3, setOopt3] = useState(false);
  const [oopt4, setOopt4] = useState(false);
  const [oopt5, setOopt5] = useState(false);
  const [ooption, setOoption] = useState(false);
  const [oopts1, setOopts1] = useState(false);
  const [oopts2, setOopts2] = useState(false);
  const [oopts3, setOopts3] = useState(false);
  const [oopts4, setOopts4] = useState(false);
  const [oopts5, setOopts5] = useState(false);
  const [ooptions, setOoptions] = useState(false);
  const [errors, setErrors] = useState(false);

  const dispatch = useDispatch();
  const currentUserId = Cookies.get("userid");
  const [campaigns, setCampaigns] = useState([]); // State to store conversions data
  const [brandcampaigns, setBrandCampaigns] = useState([]); // State to store brand awareness data
  const [finishedcampaigns, setFinishedCampaigns] = useState([]); //state to store finished camp data
  const [dashMap, setdashMap] = useState(new Map());

  const { userAccountStatus } = useSelector((state) => state.userAccountStatus);
  useEffect(() => {
    const companyId = Cookies.get("userid");
    if (companyId) {
      dispatch(getUserAccountStatus({ companyId: companyId })).then(
        (response) => {
          dispatch(setUserAccountStatus(response?.payload));
        }
      );
    }
  }, [dispatch]);

  const setValues = (camp) => {
    let tapproved = 0,
      trejected = 0,
      tpending = 0;
    camp.map((ele) => {
      const { _id } = ele;
      setdashMap((prevMap) => new Map(prevMap.set(_id, true)));
    });
  };

  const { status } = useSelector((state) => state.admin);
  // console.log("status", status);

  const [loadingCampaignID, setLoadingCampaignID] = useState(null);
  const [campLoading, setCamLoading] = useState(false);
  const fetchCampaigns = async () => {
    try {
      setCamLoading(true);
      const response = await ApiInterceptor.get(
        `campaign/company/${currentUserId}`
      );
      if (response?.status === 200) {
        setCampaigns(response?.data?.data);
        setBrandCampaigns(response?.data?.brands);
        setFinishedCampaigns(response?.data?.finished);
        setValues(response?.data.data);
        setCamLoading(false);
      }
    } catch (error) {
      setErrors(true);
    } finally {
      setErrors(false);
      setCamLoading(false);
    }
  };
  useEffect(() => {
    fetchCampaigns();
    const handleNotification = (notification) => {
      setLoadingCampaignID(notification?.campaignId);
      fetchCampaigns();
    };

    subscribeToNotifications(currentUserId, handleNotification);

    return () => {
      // Cleanup subscriptions if needed
    };
  }, [currentUserId]);

  let total_pendings = 0;
  const calculatePendings = (camp, pendingCounts) => {
    camp.map((ele) => {
      const { applicationStatusTimeline } = ele;
      applicationStatusTimeline.map((ele) => {
        const { status } = ele;
        if (status === "Approval Waiting") {
          pendingCounts += 1;
        }
      });
    });
    return pendingCounts;
  };
  // ------------->approved influecers<----------------
  let total_approves = 0;
  let brand_approves = 0;
  let finished_approves = 0;
  let camp_approves = 0;

  const calculateApproves = (camp, approvedCounts) => {
    camp.map((ele) => {
      // cpm
      const { cpm } = ele;

      const { applicationStatusTimeline } = ele;
      applicationStatusTimeline.map((ele) => {
        const { status, price } = ele;

        if (status === "Approved") {
          approvedCounts += 1;
        }
      });
    });
    return approvedCounts;
  };
  camp_approves = calculateApproves(campaigns, 0);
  brand_approves = calculateApproves(brandcampaigns, 0);
  finished_approves = calculateApproves(finishedcampaigns, 0);
  total_approves = camp_approves + brand_approves + finished_approves;
  // console.log("total_approves", total_approves);

  // pending count calculation
  let camp__pending_cnt = calculatePendings(campaigns, 0);
  let brand_pending_cnt = calculatePendings(brandcampaigns, 0);
  let finished_pending_cnt = calculatePendings(finishedcampaigns, 0);
  total_pendings = camp__pending_cnt + brand_pending_cnt + finished_pending_cnt;
  // console.log("total_pendings", total_pendings);

  // potential reach: so it has activeCampaignFollowers in each campaign
  let total_potentail_reach = 0;
  let camp_potentail_reach = 0,
    brand_potentail_reach = 0,
    finished_potentail_reach = 0;
  const calculatePotentialReach = (camp, potentialReach) => {
    camp.map((ele) => {
      const { activeCampaignFollowers } = ele;
      potentialReach += activeCampaignFollowers;
    });
    return potentialReach;
  };
  camp_potentail_reach = calculatePotentialReach(campaigns, 0);
  brand_potentail_reach = calculatePotentialReach(brandcampaigns, 0);
  finished_potentail_reach = calculatePotentialReach(finishedcampaigns, 0);
  total_potentail_reach =
    camp_potentail_reach + brand_potentail_reach + finished_potentail_reach;

  const [selectedOption, setSelectedOption] = useState("Date");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // campaign data
  const [campaignApiData, setCampaignApiData] = useState(null);
  const [dashdata, setDashData] = useState(null);

  const getTotal = (data) => {
    let total_approves = 0,
      total_pendings = 0,
      total_clicks = 0,
      potential_reachs = 0,
      total_earnings = 0,
      total_posts = 0;
    // console.log("Mappping", dashMap);
    data.map((it) => {
      const {
        approved_influencer,
        campaign_id,
        clicks,
        pending_influencers,
        potential_reach,
        total_earning,
        total_post,
      } = it;
      if (dashMap.has(campaign_id)) {
        total_approves += approved_influencer;
        total_pendings += pending_influencers;
        total_clicks += clicks;
        potential_reachs += potential_reach;
        total_earnings += total_earning;
        total_posts += total_post;
      }
    });
    setDashData({
      total_approves,
      total_clicks,
      total_posts,
      total_pendings,
      total_earnings,
      total_posts,
      potential_reachs,
    });
  };

  const [count, setCount] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { loading } = useSelector((state) => state.loading);
  const userid = Cookies.get("userid");
  // console.log("userid", userid);

  const [campDashData, setCampDashData] = useState([]);

  const currentYear = new Date()?.getFullYear();
  const currentMonth = new Date()?.getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [chartDLoading, setChartDLoading] = useState(true);
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // dispatch(showLoading());
  //     try {
  //       let url = `https://api.cloutin.co/api/campaigndata/getgraphData/${userid}`;
  //       if (months.indexOf(selectedMonth) !== -1) {
  //         url = `https://api.cloutin.co/api/campaigndata/getgraphData/${userid}?year=${selectedYear}&month=${
  //           0 + months.indexOf(selectedMonth) + 1
  //         }`;
  //       } else {
  //         url = `https://api.cloutin.co/api/campaigndata/getgraphData/${userid}?year=${selectedYear}`;
  //       }
  //       const response = await axios.get(
  //         // "https://api.cloutin.co/api/campaigndata/getgraphData"
  //         // `https://api.cloutin.co/api/campaigndata/getgraphData/${userid}?year=${2024}`
  //         url
  //       );
  //       setChartData(response?.data?.data);
  //       // dispatch(hideLoading());
  //     } catch (error) {
  //       toast.error(error?.response?.data?.message || "Error fetching data");
  //       dispatch(hideLoading());
  //     } finally {
  //       // dispatch(hideLoading());
  //       setChartDLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [selectedYear, selectedMonth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // let url = `https://api.cloutin.co/api/campaigndata/getgraphData/${userid}?year=${selectedYear}&month=${selectedMonth}`;
        let url = `${config.REACT_APP_BACKEND_SERVICE_URL}campaigndata/getgraphData/${userid}?year=${selectedYear}&month=${selectedMonth}`;
        const response = await axios.get(url);
        setChartData(response?.data?.data);
      } catch (error) {
        toast.error(error?.response?.data?.message || "Error fetching data");
      } finally {
        setChartDLoading(false);
      }
    };

    fetchData();
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    const fetchdash = async () => {
      try {
        const response = await ApiInterceptor.get(
          `campaigndata/getcampdashdata/${userid}`
        );
        // console.log(response?.data?.data, "camp dash data");
        setCampDashData(response?.data?.data);
      } catch (error) {
        setErrors(true);
        toast.error(error?.message || "error in fetching dashboard details", {
          duration: 1000,
        });
      } finally {
        setErrors(false);
      }
    };
    fetchdash();
  }, []);

  useEffect(() => {
    const fetchConversions = async () => {
      try {
        const response = await ApiInterceptor.get(
          `influencerAnalytics/getcampaigndetails`
        );
        // console.log(response.data);
        setCount(response?.data?.stats);
      } catch (error) {
        setErrors(true);
      } finally {
        setErrors(false);
      }
    };

    fetchConversions();
  }, []); // Empty dependency array means this effect runs only once when the component mounts

  const [cnt, setCounts] = useState(0);
  if (campaignApiData && cnt === 0) {
    getTotal(campaignApiData);
    setCounts(cnt + 1);
  }

  const [openDisablePageModal, setOpenDisablePageModal] = useState(false);
  useEffect(() => {
    if (userAccountStatus) {
      setOpenDisablePageModal(true);
    }
  }, [userAccountStatus]);

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const options = { month: "short" };
    return date.toLocaleDateString(undefined, options);
  };

  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [page3, setPage3] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItem2 = page2 * itemsPerPage;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage;
  const indexOfLastItem3 = page3 * itemsPerPage;
  const indexOfFirstItem3 = indexOfLastItem3 - itemsPerPage;
  const conversionItemsData = campaigns?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandItemsData = brandcampaigns?.slice(
    indexOfFirstItem2,
    indexOfLastItem2
  );
  const finishedItemsData = finishedcampaigns?.slice(
    indexOfFirstItem3,
    indexOfLastItem3
  );
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePageChange2 = (page) => {
    setPage2(page);
  };
  const handlePageChange3 = (page) => {
    setPage3(page);
  };

  const handleRefresh = (tableName) => {
    switch (tableName) {
      case "conversion":
        fetchCampaigns();
        break;
      case "brand_awareness":
        fetchCampaigns();
        break;
      case "completed":
        fetchCampaigns();
        break;
      default:
        return;
    }
  };

  if (errors) {
    return <ErrorPage />;
  }
  if (userAccountStatus === true) {
    return <OverlayDisableAccountPage isOpen={true} />;
  }

  return (
    <>
      {!loading ? (
        <div className="p-[20px] mr-[10px] md:p-2">
          <div className="flex justify-start gap-4 campaign-upper">
            {/* graph  */}
            <div className=" rounded-lg shadow-lg hover:shadow-xl transition duration-300 graph-css w-[50%] bg-white campaign-graph">
              <div className="flex justify-between items-center p-4 flex-row">
                <div className="flex items-center">
                  <h1 className="text-black font-extrabold text-md">
                    Total Sales Generated
                  </h1>
                </div>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col">
                    <label htmlFor="year">Year</label>
                    <select
                      className="p-1 border border-gray-300 rounded-md text-sm"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="month">Month</label>
                    <select
                      className="p-1 border border-gray-300 rounded-md text-sm"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="h-full">
                {chartData.length === 0 ? (
                  <div className="rounded-lg  transition duration-300 graph-css w-[50%] bg-white campaign-graph flex justify-center items-center">
                    <div className="p-4 h-[300px] flex items-center justify-center">
                      <TbDatabaseOff className="text-gray-500 text-5xl mb-2" />
                      <h1 className="text-gray-500 text-2xl ml-2">
                        No Data Found
                      </h1>
                    </div>
                  </div>
                ) : (
                  <>
                    <CampaignsGraphReact chartData={chartData} />
                  </>
                )}
              </div>
            </div>

            <div className="lg:w-1/2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 campaign-dash-card w-[50%]">
              <CampaignCard
                iconImage="/Images/influencer_main.png"
                dash_data={
                  campDashData !== null ? campDashData?.approved_influencer : 0
                }
                dash_name={"Approved Influencers"}
              />
              <CampaignCard
                dashimg={<TbHandClick size={30} />}
                dash_data={campDashData != null ? campDashData?.clicks : 0}
                dash_name={"Clicks"}
              />
              <CampaignCard
                // iconImage="/Images/sales.png"
                dashimg={
                  <GiReceiveMoney size={30} className="text-green-700" />
                }
                dash_data={
                  campDashData !== null
                    ? campDashData?.total_earning?.toFixed(2)
                    : 0
                }
                dash_name={"Total Earnings"}
              />
              <CampaignCard
                iconImage="/Images/influencer_main.png"
                dash_data={
                  campDashData !== null ? campDashData.pending_influencers : 0
                }
                dash_name={"Pending Influencers"}
              />
              <CampaignCard
                iconImage="/Images/reach.png"
                dash_data={
                  campDashData !== null ? campDashData?.potential_reach : 0
                }
                dash_name={"Potential reach"}
              />
              <CampaignCard
                iconImage="/Images/social_main.png"
                dash_data={campDashData !== null ? campDashData?.total_post : 0}
                dash_name={" Total Posts"}
              />
            </div>
          </div>

          {/* tabs here */}

          <Box sx={{ width: "100%", paddingTop: "60px" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", color: "black" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "black", // Change this to your desired color
                  },
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    flexGrow: 1,
                    "&:hover": {
                      color: "black",
                      backgroundColor: "white",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "white",
                    },
                  },
                }}
              >
                <Tab label="Conversion Campaign " {...a11yProps(0)} />
                <Tab label="Brand Awareness Campaigns " {...a11yProps(1)} />
                <Tab label="Finished Compaign" {...a11yProps(2)} />
              </Tabs>
            </Box>

            {/* conversion campaign list starts */}
            <CustomTabPanel value={value} index={0}>
              <div className="flex mt-[10px] mb-[5px] justify-between">
                <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black">
                  Conversion Campaign List
                </h1>

                <div className="flex items-center gap-3">
                  <IoMdRefresh
                    className={`cursor-pointer
                  ${campLoading ? "animate-spin" : ""}
                  `}
                    size={30}
                    onClick={() => handleRefresh("conversion")}
                  />
                  <img
                    className="w-5 h-5 cursor-pointer"
                    src="/Images/option.png"
                    alt="option"
                    onClick={() => setOption(!option)}
                  />
                </div>
              </div>

              <div className="bg-gray-50 py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full relative shadow-md rounded-md">
                <table className="table-auto md:gap-2 text-center lg:w-[95%]">
                  <thead>
                    <tr className="bg-blue-500 text-white shadow-md rounded-md">
                      <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8 py-1">
                        Campaign Name
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-2">
                        status
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        No. of Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Approved Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Rejected Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-1">
                        Spent
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Clicks
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-4">
                        Conversions
                      </th>
                      <th className="text-xs pr-[60px] md:text-[14px]">
                        Action
                      </th>

                      <th className="text-xs pr-[60px] md:text-[14px]">
                        Created At
                      </th>
                      {opt1 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          {/* Rejected */}
                          Total No. Of Likes
                        </th>
                      )}
                      {opt2 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          {/* Clicks */}
                          Total No. Of Comments
                        </th>
                      )}
                      {opt3 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold">
                          {/* Total Followers */}
                          Total No. Of Purchases
                        </th>
                      )}
                      {opt4 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          {/* Conversions */}
                          Revenue
                        </th>
                      )}
                      {opt5 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          {/* In Reviews */}
                          additional
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
                    {campLoading ? (
                      <div className="flex justify-center items-center h-[200px] w-full">
                        <Loader loadingText={" Conversions Data"} />
                      </div>
                    ) : (
                      <>
                        {" "}
                        {conversionItemsData?.map(
                          ({
                            Influencers,
                            applicationStatusTimeline,
                            _id,
                            cover,
                            status,
                            name,
                            no_of_purchases,
                            cpm,
                            statusCampaign,
                            scheduleDate,
                            scheduleTime,
                            isCampaignScheduled,
                            willCampaignBeScheduled,
                            campaignLikes,
                            campaignComments,
                            campaignFollowers,
                            campaignPurchases,
                            campaignRevenue,
                            createdAt,
                          }) => (
                            <tr
                              key={_id}
                              className="border-b-2 border-gray-200 "
                            >
                              <td
                                className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer "
                                onClick={() => {
                                  navigate(`/campaign/${_id}`);
                                }}
                              >
                                <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                                  <h1 className="hover:underline hover:text-blue-500">
                                    {name}
                                  </h1>
                                </div>
                              </td>
                              <td
                                className={`text-md pt-[20px] ${
                                  status === "Active"
                                    ? "text-[#00B528]"
                                    : "text-[#FF0000]"
                                }  md:text-[14px] font-bold`}
                              >
                                {_id === loadingCampaignID
                                  ? "updating..."
                                  : status}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                                {Influencers.length}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                                {applicationStatusTimeline.length === 0
                                  ? 0
                                  : applicationStatusTimeline.reduce(
                                      (cnt, { status }) => {
                                        if (status === "Approved")
                                          return cnt + 1;
                                        else return cnt;
                                      },
                                      0
                                    )}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-8">
                                {applicationStatusTimeline.length === 0
                                  ? 0
                                  : applicationStatusTimeline.reduce(
                                      (cnt, { status }) => {
                                        if (status === "Disapproved")
                                          return cnt + 1;
                                        else return cnt;
                                      },
                                      0
                                    )}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {applicationStatusTimeline.length > 0
                                  ? applicationStatusTimeline
                                      .reduce(
                                        (acc, { status, price, post_url }) => {
                                          if (
                                            status === "Approved" &&
                                            post_url
                                          ) {
                                            return acc + price;
                                          }
                                          return acc;
                                        },
                                        0
                                      )
                                      .toFixed(2)
                                  : (0).toFixed(2)}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {count
                                  .reduce((acc, { campaign_id, hits }) => {
                                    if (campaign_id === _id) {
                                      return acc + hits;
                                    }
                                    return acc;
                                  }, 0)
                                  .toFixed(0)}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-2">
                                {count
                                  .reduce(
                                    (
                                      acc,
                                      {
                                        campaign_id,
                                        add_to_carts,
                                        hits,
                                        no_of_purchases,
                                      }
                                    ) => {
                                      if (campaign_id === _id)
                                        return (
                                          acc +
                                          hits +
                                          no_of_purchases +
                                          add_to_carts
                                        );
                                      return acc;
                                    },
                                    0
                                  )
                                  .toFixed(0)}
                              </td>
                              <td className="flex pt-[20px] pb-6 space-x-[5px]  md:text-[14px]">
                                {/* {isCampaignScheduled &&
                            scheduleDate.length > 0 &&
                            scheduleTime.length > 0 ? (
                              <button disabled>Scheduled Right Now</button>
                            ) : (
                              <ButtonCampaign
                                label="Pause"
                                color="#D6F0FF"
                                tcolor="black"
                                id={currentUserId}
                                campaign_name={name}
                                campaign_id={_id}
                                statusCampaign={statusCampaign}
                              />
                            )} */}
                                {willCampaignBeScheduled ===
                                  "Will be Scheduled" &&
                                isCampaignScheduled === false ? (
                                  <button
                                    disabled
                                    className="bg-violet-300 py-1 px-1 text-sm text-center w-full sm:w-auto rounded-lg"
                                  >
                                    Scheduled @ <br />
                                    <span className="block sm:inline">
                                      {scheduleDate}
                                    </span>{" "}
                                    <br className="block sm:hidden" />{" "}
                                    <span className="block sm:inline">
                                      {scheduleTime}
                                    </span>
                                  </button>
                                ) : (
                                  <ButtonCampaign
                                    label="Scheduled"
                                    color="#00FF00" // Example color for the "Scheduled" button
                                    tcolor="white" // Example text color for the "Scheduled" button
                                    id={currentUserId}
                                    campaign_name={name}
                                    campaign_id={_id}
                                    statusCampaign={statusCampaign}
                                  />
                                )}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-2">
                                {formateDate(createdAt)}
                              </td>
                              {opt1 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignLikes || 0}
                                </td>
                              )}
                              {opt2 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignComments || 0}
                                </td>
                              )}
                              {opt3 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignPurchases || 0}
                                </td>
                              )}
                              {opt4 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignRevenue || 0}
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <PaginationComponent
                  currentPage={page}
                  itemsPerPage={itemsPerPage}
                  totalItems={campaigns.length}
                  onPageChange={handlePageChange}
                />

                {option && (
                  <div className="bg-white md:text-lg shadow-md rounded-lg text-xs absolute top-0 md:right-2 border p-2 grid grid-cols-2 md:grid-cols-3 gap-7 flex-wrap ">
                    {[
                      {
                        state: opt1,
                        setter: setOpt1,
                        label: "Total No. Of Likes",
                      },
                      {
                        state: opt2,
                        setter: setOpt2,
                        label: "Total No. Of Comments",
                      },
                      {
                        state: opt3,
                        setter: setOpt3,
                        label: "Total No. Of Purchases",
                      },
                      {
                        state: opt4,
                        setter: setOpt4,
                        label: "Revenue Generated",
                      },
                    ].map((item, index) => (
                      <button
                        key={index}
                        className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md shadow-sm transition-all duration-200 hover:shadow-md ${
                          item.state
                            ? "bg-[#D9F1FF] border-2 border-[#00A3FF] font-medium"
                            : "bg-white hover:bg-blue-50"
                        }`}
                        onClick={() => item.setter(!item.state)}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              {/* conversion campaign list ends */}
            </CustomTabPanel>
            {/* conversion campaign list ends */}

            {/* brand awarness campaign list starts */}
            <CustomTabPanel value={value} index={1}>
              <div className="flex mt-[10px] mb-[5px] justify-between">
                <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black ">
                  Brand Awareness Campaigns List
                </h1>
                <div className="flex items-center justify-center gap-2">
                  <IoMdRefresh
                    className={`cursor-pointer
                  ${campLoading ? "animate-spin" : ""}
                  `}
                    size={30}
                    onClick={() => handleRefresh("brand_awareness")}
                  />

                  <img
                    className="w-5 h-5 cursor-pointer"
                    src="/Images/option.png"
                    alt="option"
                    onClick={() => setOoption(!ooption)}
                  />
                </div>
              </div>

              <div className="bg-gray-50 rounded-md shadow-md relative py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
                <table className="table-auto md:gap-2 text-center lg:w-[95%]">
                  <thead>
                    <tr className="bg-blue-500 text-white shadow-md rounded-md">
                      <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8 py-2">
                        Campaign Name
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-2">
                        status
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        No. of Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Approved Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Rejected Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-1">
                        Spent
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Clicks
                      </th>
                      <th className="text-xs pr-[60px] md:text-[14px]">
                        Action
                      </th>
                      <th className="text-xs pr-[60px] md:text-[14px]">
                        Created At
                      </th>
                      {oopt1 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Total No. Of Likes
                        </th>
                      )}
                      {oopt2 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Total No. Of Comments
                        </th>
                      )}
                      {oopt3 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold">
                          Total No. Of Purchases
                        </th>
                      )}
                      {oopt4 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Revenue Generated
                        </th>
                      )}
                      {oopt5 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Conversions
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
                    {campLoading ? (
                      <div className="flex justify-center items-center h-[200px] w-full">
                        <Loader loadingText={" BrandAwareness Data"} />
                      </div>
                    ) : (
                      <>
                        {brandItemsData?.map(
                          ({
                            Influencers,
                            applicationStatusTimeline,
                            _id,
                            cover,
                            status,
                            name,
                            statusCampaign,
                            no_of_purchases,
                            cpm,
                            isCampaignScheduled,
                            scheduleDate,
                            scheduleTime,
                            willCampaignBeScheduled,
                            campaignLikes,
                            campaignComments,
                            campaignPurchases,
                            campaignRevenue,
                            createdAt,
                          }) => (
                            <tr
                              key={_id}
                              className="border-b-2 border-gray-200"
                            >
                              <td
                                className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer "
                                onClick={() => {
                                  navigate(`/campaign/${_id}`);
                                }}
                              >
                                <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                                  <h1>{name}</h1>
                                </div>
                              </td>
                              <td
                                className={`text-md pt-[20px] ${
                                  status === "Active"
                                    ? "text-[#00B528]"
                                    : "text-[#FF0000]"
                                }  md:text-[14px] font-bold`}
                              >
                                {_id === loadingCampaignID
                                  ? "updating..."
                                  : status}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                                {Influencers.length}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                                {applicationStatusTimeline?.length === 0
                                  ? 0
                                  : applicationStatusTimeline?.reduce(
                                      (cnt, { status }) => {
                                        if (status === "Approved")
                                          return cnt + 1;
                                        return cnt;
                                      },
                                      0
                                    )}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-8">
                                {applicationStatusTimeline?.length === 0
                                  ? 0
                                  : applicationStatusTimeline.reduce(
                                      (cnt, { status }) => {
                                        if (status === "Disapproved")
                                          return cnt + 1;
                                        else return cnt;
                                      },
                                      0
                                    )}
                              </td>
                              {/* <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                            {cpm === NaN
                              ? 0
                              : cpm *
                                1000 *
                                applicationStatusTimeline?.map(
                                  ({ status, price }, cnt) => {
                                    if (status === "Approved")
                                      return cnt + price;
                                    else return cnt;
                                  }
                                )}


                          </td> */}

                              {/* addded code to handled the NaN value */}
                              <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-4">
                                {applicationStatusTimeline?.reduce(
                                  (acc, { status, price, post_url }) => {
                                    if (status === "Approved" && post_url) {
                                      return acc + price;
                                    }
                                    return acc;
                                  },
                                  0
                                )}
                              </td>

                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {count
                                  .reduce((acc, { campaign_id, hits }) => {
                                    if (campaign_id === _id) {
                                      return acc + hits;
                                    }
                                    return acc;
                                  }, 0)
                                  .toFixed(0)}
                              </td>
                              <td className="flex pt-[20px] pb-6 space-x-[5px]  md:text-[14px]">
                                {/* isCampaignScheduled &&
                            scheduleDate.length > 0 &&
                            scheduleTime.length > 0 */}
                                {willCampaignBeScheduled ===
                                "Will be Scheduled" ? (
                                  // <button
                                  //   disabled
                                  //   className="bg-green-500 text-white rounded-md px-2 py-1 cursor-not-allowed"
                                  // >
                                  //   Scheduled
                                  // </button>
                                  <button
                                    disabled
                                    className="bg-violet-300 py-1 px-1 text-sm text-center w-full sm:w-auto rounded-lg"
                                  >
                                    Scheduled @ <br />
                                    <span className="block sm:inline">
                                      {scheduleDate}
                                    </span>{" "}
                                    <br className="block sm:hidden" />{" "}
                                    <span className="block sm:inline">
                                      {scheduleTime}
                                    </span>
                                  </button>
                                ) : (
                                  <ButtonCampaign
                                    label="Pause"
                                    color="#D6F0FF"
                                    tcolor="black"
                                    id={currentUserId}
                                    campaign_name={name}
                                    campaign_id={_id}
                                    statusCampaign={statusCampaign}
                                  />
                                )}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {formateDate(createdAt)}
                              </td>
                              {oopt1 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignLikes || 0}
                                </td>
                              )}
                              {oopt2 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignComments || 0}
                                </td>
                              )}
                              {oopt3 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignPurchases || 0}
                                </td>
                              )}
                              {oopt4 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {campaignRevenue || 0}
                                </td>
                              )}
                              {oopt5 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {count
                                    .reduce(
                                      (
                                        acc,
                                        {
                                          campaign_id,
                                          add_to_carts,
                                          hits,
                                          no_of_purchases,
                                        }
                                      ) => {
                                        if (campaign_id === _id)
                                          return (
                                            acc +
                                            hits +
                                            no_of_purchases +
                                            add_to_carts
                                          );
                                        return acc;
                                      },
                                      0
                                    )
                                    .toFixed(0)}
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <PaginationComponent
                  currentPage={page2}
                  itemsPerPage={itemsPerPage}
                  totalItems={brandcampaigns.length}
                  onPageChange={handlePageChange2}
                />
                {ooption && (
                  <div className="bg-white md:text-lg shadow-md rounded-lg text-xs absolute top-0 md:right-2 border p-8 grid grid-cols-2 md:grid-cols-3 gap-7 flex-wrap ">
                    {[
                      {
                        state: oopt4,
                        setter: setOopt4,
                        label: "Total No. Of Likes",
                      },
                      {
                        state: oopt1,
                        setter: setOopt1,
                        label: "Total No. Of Comments",
                      },
                      {
                        state: oopt5,
                        setter: setOopt5,
                        label: "Total No. Of Purchases",
                      },
                      {
                        state: oopt2,
                        setter: setOopt2,
                        label: "Revenue Generated",
                      },
                      {
                        state: oopt3,
                        setter: setOopt3,
                        label: "Conversions",
                      },
                    ].map((item, index) => (
                      <button
                        key={index}
                        className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md shadow-sm transition-all duration-200 hover:shadow-md ${
                          item.state
                            ? "bg-[#D9F1FF] border-2 border-[#00A3FF] font-medium"
                            : "bg-white hover:bg-blue-50"
                        }`}
                        onClick={() => item.setter(!item.state)}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </CustomTabPanel>
            {/* brand awarness campaign list ends */}

            {/* finished campaign list starts */}
            <CustomTabPanel value={value} index={2}>
              <div className="flex mt-[10px] mb-[5px]  justify-between">
                <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black ">
                  Finished Compaign
                </h1>
                <div className="flex items-center justify-center gap-2">
                  <IoMdRefresh
                    className={`cursor-pointer
                  ${campLoading ? "animate-spin" : ""}
                  `}
                    size={30}
                    onClick={() => handleRefresh("conversion")}
                  />
                  <img
                    className="w-5 h-5 cursor-pointer"
                    src="/Images/option.png"
                    alt="option"
                    onClick={() => setOoptions(!ooptions)}
                  />
                </div>
              </div>
              <div className="bg-gray-50 shadow-md rounded-md relative  py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
                <table className="table-auto md:gap-2 text-center lg:w-[95%]">
                  <thead>
                    <tr className="bg-blue-500 text-white shadow-md rounded-md">
                      <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8 py-2">
                        Campaign Name
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-2">
                        status
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        No. of Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Approved Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Rejected Influencers
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px] pl-1">
                        Spent
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Clicks
                      </th>
                      <th className="text-xs pr-[20px] md:text-[14px]">
                        Created At
                      </th>
                      {oopts1 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Total No. Of Likes
                        </th>
                      )}
                      {oopts2 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Total No. Of Comments
                        </th>
                      )}
                      {oopts3 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold">
                          Total No. Of Purchases
                        </th>
                      )}
                      {oopts4 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Revenue Generated
                        </th>
                      )}
                      {oopts5 && (
                        <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                          Conversions
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
                    {campLoading ? (
                      <div className="flex justify-center items-center h-[200px] w-full">
                        <Loader loadingText={" Finished Campaign Data"} />
                      </div>
                    ) : (
                      <>
                        {finishedItemsData &&
                          finishedItemsData?.map((i) => (
                            <tr
                              key={i?._id}
                              className="border-b-2 border-gray-200"
                            >
                              <td
                                className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer "
                                onClick={() => {
                                  navigate(`/campaign/${i?._id}`);
                                }}
                              >
                                <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                                  <h1>{i?.name}</h1>
                                  {/* <h1 className="mx-[5px]">Campaign</h1> */}
                                </div>
                              </td>
                              <td
                                className={`text-md pt-[20px] ${
                                  i?.statusCampaign === "Active"
                                    ? "text-[#00B528]"
                                    : "text-[#FF0000]"
                                }  md:text-[14px] font-bold`}
                              >
                                {i?._id === loadingCampaignID
                                  ? "updating..."
                                  : i.statusCampaign}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                                {i.Influencers.length}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                                {i?.applicationStatusTimeline?.length === 0
                                  ? 0
                                  : i.applicationStatusTimeline.reduce(
                                      (cnt, { status }) => {
                                        if (status === "Approved")
                                          return cnt + 1;
                                        return cnt;
                                      },
                                      0
                                    )}
                              </td>
                              <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-8">
                                {i?.applicationStatusTimeline?.length === 0
                                  ? 0
                                  : i.applicationStatusTimeline.reduce(
                                      (cnt, { status }) => {
                                        if (status === "Disapproved")
                                          return cnt + 1;
                                        else return cnt;
                                      },
                                      0
                                    )}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {i.applicationStatusTimeline.length > 0
                                  ? i.applicationStatusTimeline
                                      .reduce(
                                        (acc, { status, price, post_url }) => {
                                          if (
                                            status === "Approved" &&
                                            post_url
                                          ) {
                                            return acc + price;
                                          }
                                          return acc;
                                        },
                                        0
                                      )
                                      .toFixed(2)
                                  : (0).toFixed(2)}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {count
                                  .reduce((acc, { campaign_id, hits }) => {
                                    if (campaign_id === i._id) {
                                      return acc + hits;
                                    }
                                    return acc;
                                  }, 0)
                                  .toFixed(0)}
                              </td>
                              <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                                {formateDate(i?.createdAt)}
                              </td>
                              {oopts1 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {i.campaignLikes || 0}
                                </td>
                              )}
                              {oopts2 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {i.campaignComments || 0}
                                </td>
                              )}
                              {oopts3 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {i.campaignPurchases || 0}
                                </td>
                              )}
                              {oopts4 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {i.campaignRevenue || 0}
                                </td>
                              )}
                              {oopts5 && (
                                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                                  {count
                                    .reduce(
                                      (
                                        acc,
                                        {
                                          campaign_id,
                                          add_to_carts,
                                          hits,
                                          no_of_purchases,
                                        }
                                      ) => {
                                        if (campaign_id === i._id)
                                          return (
                                            acc +
                                            hits +
                                            no_of_purchases +
                                            add_to_carts
                                          );
                                        return acc;
                                      },
                                      0
                                    )
                                    .toFixed(0)}
                                </td>
                              )}
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
                <PaginationComponent
                  currentPage={page3}
                  itemsPerPage={itemsPerPage}
                  totalItems={finishedcampaigns.length}
                  onPageChange={handlePageChange3}
                />

                {ooptions && (
                  <div className="bg-white md:text-lg shadow-md rounded-lg text-xs absolute top-0 md:right-2 border p-8 grid grid-cols-2 md:grid-cols-3 gap-7 flex-wrap ">
                    {[
                      {
                        state: oopts4,
                        setter: setOopts4,
                        label: "Total No. Of Likes",
                      },
                      {
                        state: oopts1,
                        setter: setOopts1,
                        label: "Total No. Of Comments",
                      },
                      {
                        state: oopts5,
                        setter: setOopts5,
                        label: "Total No. Of Purchases",
                      },
                      {
                        state: oopts2,
                        setter: setOopts2,
                        label: "Revenue Generated",
                      },
                      {
                        state: oopts3,
                        setter: setOopts3,
                        label: "Conversions",
                      },
                    ].map((item, index) => (
                      <button
                        key={index}
                        className={`py-2 px-3 text-sm border border-[#00A3FF] rounded-md shadow-sm transition-all duration-200 hover:shadow-md ${
                          item.state
                            ? "bg-[#D9F1FF] border-2 border-[#00A3FF] font-medium"
                            : "bg-white hover:bg-blue-50"
                        }`}
                        onClick={() => item.setter(!item.state)}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </CustomTabPanel>
            {/* finished campaign list ends */}
          </Box>
        </div>
      ) : (
        <Loader loadingText={"Campaigns"} />
      )}
    </>
  );
}

export default Campaign;
