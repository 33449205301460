import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import GoogleRegister from "./Pages/GoogleReg";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Wallet from "./Pages/Wallet";
import UniqueIdRedirect from "./Pages/UniqueIdRedirect";
import LoginWithGoogleTest from "./Pages/login_test";
import InstagramTest from "./Pages/instagram_main";
import AdminHome from "./Pages/AdminHome";
import { SharedStateProvider } from "./context/SharedStateContext";
import InfluencerLogin from "./Pages/influencerLogin";
import InfluencerRegister from "./Pages/influencerRegister";
import InfluencerGoogleRegister from "./Pages/influencerGoogleReg";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";
import Review from "./Component/MarketPlace/Review";
import SuccessPage from "./Pages/SuccessPage";
import TransactionDetails from "./Component/admin/TransactionDetails";
import UsermarktplacePage from "./Pages/UsermarktplacePage";
import { useEffect, useState } from "react";
import LoadingOnWebsite from "./utils/LoadingOnWebsite";
import ConnectionStatus from "./ConnectionStatus";
import AdminRoute from "./routes/AdminRoute";
import AdminLogin from "./Pages/AdminLogin";
import PageNotFound from "./Pages/PageNotFound";
import EmailVerify from "./Pages/EmailVerify";
import {
  checkEmailStatus,
  selectEmailVerificationStatus,
} from "./redux/Slice/EmailVerification";
import EmailVerificationComponent from "./Component/EmailVerificationComponent";
import Cookies from "js-cookie";
import AdminKycVerification from "./Component/admin/AdminKycVerification";
import { updateAdminStatus } from "./redux/Slice/AdminSlice";
import AdminRefundDetail from "./Component/admin/AdminRefundDetail";
import AdminRefundDetailforMarketPlace from "./Component/admin/AdminRefundDetailforMarketPlace";
axios.defaults.withCredentials = true;
function App() {
  const { currentUser } = useSelector((state) => state.user);
  const current = currentUser?.others ? currentUser?.others : currentUser;
  // const isAdmin=currentUser?.isAdmin;
  const { isAdmin } = useSelector((state) => state.admin);

  const [loading, setLoading] = useState(true);

  const [networkPresent, setNetworkPresent] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setNetworkPresent(true);
    };

    const handleOffline = () => {
      setNetworkPresent(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  });

  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(time);
  });

  const dispatch = useDispatch();

  const emailVerificationStatus = useSelector(selectEmailVerificationStatus);

  const current1 = Cookies.get("userid");

  useEffect(() => {
    dispatch(checkEmailStatus(currentUser?.id));
  }, [dispatch, currentUser]);

  const getToken = () => localStorage.getItem("token");
  useEffect(() => {
    getToken();
  }, []);

  return (
    <SharedStateProvider>
      {networkPresent ? (
        loading ? (
          <LoadingOnWebsite />
        ) : (
          <>
            {currentUser && !isAdmin && !emailVerificationStatus && (
              <EmailVerificationComponent currentUserId={currentUser?.id} />
            )}
            <Routes>
              <Route
                path="/"
                element={
                  current1 ? <Navigate to="/maindashboard" /> : <Login />
                }
              />
              <Route
                path="/maindashboard"
                element={getToken ? <Home /> : <Navigate to="/" />}
              />

              <Route path="/adminlogin" element={<AdminLogin />} />
              <Route element={<AdminRoute isAdmin={isAdmin} />}>
                <Route exact path="/admin" element={<AdminHome />} />
                <Route
                  exact
                  path="/admin/campaign/page2/:id6"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/campaign/page3/:id7"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/influencers/:id8"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/transaction/details"
                  element={<TransactionDetails />}
                />
                <Route
                  exact
                  path="/admin/refund/details/:id"
                  element={<AdminRefundDetail />}
                />
                <Route
                  exact
                  path="/admin/marketplace/refund/details/:id"
                  element={<AdminRefundDetailforMarketPlace />}
                />
                <Route
                  exact
                  path="/admin/kyc-verification"
                  element={<AdminKycVerification />}
                />
                <Route
                  exact
                  path="/admin/business/transactions/:id13"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/influencers/page4/:id9"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/allpost/page5/:id10"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/affiliateCampaign"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/affiliateCampaignDetails/page2/:id12"
                  element={<AdminHome />}
                />
                <Route
                  exact
                  path="/admin/affiliate/individual/:id20"
                  element={<AdminHome />}
                />
              </Route>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/:id" element={<UniqueIdRedirect />} />
              <Route
                exact
                path="/logintest"
                element={<LoginWithGoogleTest />}
              />
              <Route exact path="/instagram" element={<InstagramTest />} />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgetPassword />}
              />
              <Route
                exact
                path="/reset-password/:id"
                element={<ResetPassword />}
              />
              <Route exact path="/register" element={<Register />} />
              <Route
                exact
                path="/influencerLogin"
                element={<InfluencerLogin />}
              />
              <Route
                exact
                path="/influencerRegister"
                element={<InfluencerRegister />}
              />
              <Route
                exact
                path="/googleRegister"
                element={<GoogleRegister />}
              />
              <Route
                exact
                path="/influencerGoogleRegister"
                element={<InfluencerGoogleRegister />}
              />
              <Route
                exact
                path="/wallet"
                element={current ? <Wallet /> : <Login />}
              />
              <Route
                exact
                path="/payment/success/page"
                element={<SuccessPage />}
              />
              <Route
                exact
                path="/single/:id1/:igUserName/:engagement_rate/:platform/:price/:categories/:followers"
                element={<Home />}
              />
              <Route
                exact
                path="/share/:id1/:igUserName/:engagement_rate/:platform/:price/:categories/:followers"
                element={<UsermarktplacePage />}
              />
              <Route exact path="/campaign/:id2" element={<Home />} />
              <Route exact path="/affiliateform/:id3" element={<Home />} />
              <Route exact path="/affiliatehome/:id4" element={<Home />} />
              <Route exact path="/affiliate/:id5" element={<Home />} />
              <Route exact path="/influencerinfo/:id6" element={<Home />} />
              {/* <Route
                exact
                path="/marketplace"
                element={<MarketplaceStandalone />}
              /> */}
              <Route exact path="/review" element={<Review />} />
              <Route exact path="/verify-email/:id" element={<EmailVerify />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </>
        )
      ) : (
        <ConnectionStatus status={networkPresent} />
      )}
    </SharedStateProvider>
  );
}

export default App;
